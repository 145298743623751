import { Flex, Text } from "@chakra-ui/react";
import { FeatureFlag } from "@elphi/utils";
import { SelectOptions } from "../../common-components/select-options/SelectOptions";
import { GateKeeper } from "../../features/gate/GateKeeper";
import useDealHooks from "../../hooks/deal.hooks";
import { DealPageViews } from "../../redux/v2/deal";
import { DealCalendarMenu } from "../deal-calendar/DealCalendarMenu";
import { CustomCalendarIcon, DotListIcon } from "../icons";

export type DealPageMenuProps = {};

const dealPageViewsOptions = [
  {
    value: "list",
    icon: <DotListIcon w={"40px"} h={"40px"} />,
    label: (
      <Flex>
        <DotListIcon w={"24px"} h={"24px"} />
        <Text
          fontSize="14px"
          fontWeight={"400"}
          alignContent={"center"}
          ml={"5px"}
        >
          as List
        </Text>
      </Flex>
    )
  },
  {
    value: "calendar",
    icon: <CustomCalendarIcon w={"40px"} h={"40px"} />,
    label: (
      <Flex>
        <CustomCalendarIcon w={"24px"} h={"24px"} />
        <Text
          fontSize="14px"
          fontWeight={"400"}
          alignContent={"center"}
          ml={"5px"}
        >
          as Calendar
        </Text>
      </Flex>
    )
  }
];

export const DealPageMenu = () => {
  const { setSelectedDealPageView, selectedDealPageView } = useDealHooks();

  return (
    <Flex>
      <GateKeeper gate={FeatureFlag.ESD_2785_Calendar}>
        <SelectOptions
          value={selectedDealPageView}
          onSelect={(option) =>
            setSelectedDealPageView(option.value as DealPageViews)
          }
          options={dealPageViewsOptions}
        />
        {selectedDealPageView === "calendar" && <DealCalendarMenu />}
      </GateKeeper>
    </Flex>
  );
};
