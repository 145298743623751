import {
  DealCalendarDateType,
  DealCalendarType,
  TimestampField
} from "@elphi/types";
import { DATES_FORMATS, elphiDate, filterDealCalendar } from "@elphi/utils";
import { isEmpty, isNumber } from "lodash";
import { getTimestampFromFirestoreObject } from "../../../firebase/firebase.utils";
import { DateType } from "../../../shared";
import { DealTableFilterState } from "../deal/deal.slice";
import { Dates, DealCalendarSliceStateFields } from "./dealCalendar.slice";

const filterDeal = (
  dealCalendar: DealCalendarType,
  filter: DealTableFilterState
) => {
  const {
    lenderIdentifierOp,
    lenderIdentifier,
    dealMilestoneOp,
    dealMilestone,
    selectedUserId
  } = filter || {};
  if (
    isEmpty(lenderIdentifier) &&
    isEmpty(selectedUserId) &&
    isEmpty(dealMilestone)
  ) {
    return !!dealCalendar;
  }

  return filterDealCalendar(dealCalendar, {
    lender: lenderIdentifier,
    lenderOp: lenderIdentifierOp,
    milestone: dealMilestone,
    milestoneOp: dealMilestoneOp,
    userId: selectedUserId
  });
};

const removeDealFromPreviousDate = (
  id: string,
  dates: Dates,
  selectedDateType: DateType,
  requestedLoanAmount?: string,
  previousDate?: string
) => {
  if (previousDate) {
    const previousDayDealCalendar = dates[selectedDateType][previousDate];
    if (previousDayDealCalendar) {
      if (previousDayDealCalendar?.totalRequestedLoanAmount) {
        previousDayDealCalendar.totalRequestedLoanAmount -= Number(
          requestedLoanAmount || 0
        );
      }
      previousDayDealCalendar.totalDealsCalendar -= 1;
      const filteredDayDealCalendar = previousDayDealCalendar.deals.filter(
        (deal) => deal.id !== id
      );
      previousDayDealCalendar.deals = filteredDayDealCalendar;
    }
  }
};

const addDealToNewDate = (
  dates: Dates,
  selectedDateType: DateType,
  isDealCalendarFiltered: boolean,
  dealCalendar: DealCalendarType,
  requestedLoanAmount?: string,
  currentDate?: string
) => {
  if (!currentDate || !isDealCalendarFiltered) {
    return undefined;
  }

  const newDayDealCalendar = dates[selectedDateType][currentDate];
  if (newDayDealCalendar) {
    if (isNumber(newDayDealCalendar?.totalRequestedLoanAmount)) {
      newDayDealCalendar.totalRequestedLoanAmount += Number(
        requestedLoanAmount || 0
      );
    }
    newDayDealCalendar.totalDealsCalendar += 1;
    newDayDealCalendar.deals.push(dealCalendar);
  } else {
    // create new date
    dates.closingDate[currentDate] = {
      deals: [dealCalendar],
      totalDealsCalendar: 1,
      totalRequestedLoanAmount: Number(requestedLoanAmount || 0)
    };
  }
};

const updatedTotalRequestedLoanAmount = (
  isDealCalendarFiltered: boolean,
  dayDealCalendar: DealCalendarDateType,
  currentDeal?: DealCalendarType,
  requestedLoanAmount?: string
) => {
  const currentRequestedLoanAmount =
    (dayDealCalendar.totalRequestedLoanAmount || 0) -
    Number(currentDeal?.RequestedLoanAmount || 0);
  if (isDealCalendarFiltered) {
    return currentRequestedLoanAmount + Number(requestedLoanAmount || 0);
  }
};

const updateDealCalendarValues = (
  id: string,
  dates: Dates,
  selectedDateType: DateType,
  isDealCalendarFiltered: boolean,
  dealCalendar: DealCalendarType,
  requestedLoanAmount?: string,
  previousDate?: string,
  currentDate?: string
) => {
  if (previousDate && currentDate) {
    const dayDealCalendar = dates[selectedDateType][currentDate];
    if (dayDealCalendar) {
      const filteredDeals = dayDealCalendar.deals.filter(
        (deal) => deal.id !== id
      );

      const currentDeal = dayDealCalendar.deals.find((deal) => deal.id === id);
      const totalRequestedLoanAmount = updatedTotalRequestedLoanAmount(
        isDealCalendarFiltered,
        dayDealCalendar,
        currentDeal,
        requestedLoanAmount
      );

      if (isDealCalendarFiltered) {
        dayDealCalendar.deals = [...filteredDeals, dealCalendar];
        if (!currentDeal) {
          dayDealCalendar.totalDealsCalendar += 1;
        }
      } else {
        dayDealCalendar.deals = filteredDeals;
        dayDealCalendar.totalDealsCalendar -= 1;
      }

      dayDealCalendar.totalRequestedLoanAmount = totalRequestedLoanAmount;
    }
  }
};

const syncDateData = ({
  dates,
  selectedDateType,
  dealCalendar,
  isDealCalendarFiltered,
  previousDate,
  currentDate
}: {
  dates: Dates;
  selectedDateType: DateType;
  dealCalendar: DealCalendarType;
  isDealCalendarFiltered: boolean;
  previousDate?: string;
  currentDate?: string;
}) => {
  const { id, RequestedLoanAmount } = dealCalendar || {};

  if (previousDate !== currentDate) {
    removeDealFromPreviousDate(
      id!,
      dates,
      selectedDateType,
      RequestedLoanAmount,
      previousDate
    );
    addDealToNewDate(
      dates,
      selectedDateType,
      isDealCalendarFiltered,
      dealCalendar,
      RequestedLoanAmount,
      currentDate
    );
  } else {
    updateDealCalendarValues(
      id!,
      dates,
      selectedDateType,
      isDealCalendarFiltered,
      dealCalendar,
      RequestedLoanAmount,
      previousDate,
      currentDate
    );
  }
};

export const syncDealCalendarData = (
  dealCalendar: DealCalendarType,
  state: DealCalendarSliceStateFields
) => {
  const { filter, selectedDateType, dates } = state;

  const { previousEstimatedClosingDate, EstimatedClosingDate, createdAt } =
    dealCalendar || {};
  const isDealCalendarFiltered = filterDeal(dealCalendar, filter);

  const createdAtFormatted = elphiDate(
    getTimestampFromFirestoreObject(createdAt as TimestampField)?.toDate()
  ).format(DATES_FORMATS.YYYY_MM_DD);

  syncDateData({
    dates,
    selectedDateType,
    dealCalendar,
    isDealCalendarFiltered,
    currentDate:
      selectedDateType === "createdAt"
        ? createdAtFormatted
        : EstimatedClosingDate,
    previousDate:
      selectedDateType === "createdAt"
        ? createdAtFormatted
        : previousEstimatedClosingDate
  });
};
