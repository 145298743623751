import { BaseEntity, PartyGroup, Status, StatusCode } from "@elphi/types";
import { removeEmptyValues } from "@elphi/utils/src/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { useRTKPagination } from "../redux/v2/hooks";
import { partyGroupApi, partyGroupSlice } from "../redux/v2/party-group";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";

export const usePartyGroup = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();

  const partyGroupState = useSelector((state: RootState) => state.partyGroup);

  const selectPartyGroup = (id: string) =>
    dispatch(partyGroupSlice.actions.selectId({ id }));
  const unSelectPartyGroup = (id: string) =>
    dispatch(partyGroupSlice.actions.unSelectId({ id }));
  const selectAllPartyGroup = () =>
    dispatch(partyGroupSlice.actions.selectAllIds());
  const unSelectAllPartyGroup = () =>
    dispatch(partyGroupSlice.actions.unSelectAll());
  const setPartyGroupId = (id: string) =>
    dispatch(partyGroupSlice.actions.setPartyGroupId({ id }));

  const [getPartyGroup, getPartyGroupApiResponse] =
    partyGroupApi.useLazyGetQuery();

  const [searchApi, searchResponse] = partyGroupApi.useLazySearchQuery();

  const paginationHandler = useRTKPagination({
    entityState: partyGroupState,
    useLazyPaginateQuery: partyGroupApi.useLazyPaginateQuery
  });

  const [updateBatchApi, updateBatchResponse] =
    partyGroupApi.useUpdateBatchMutation();

  const [getBatchApi, getBatchResponse] = partyGroupApi.useLazyGetBatchQuery();
  const [countApi] = partyGroupApi.useLazyGetCountQuery();

  const [createApi, createResponse] = partyGroupApi.useCreateMutation();

  const createPartyGroup = async (
    r: Pick<PartyGroup, "name" | "description">
  ) => {
    await createApi({
      name: r.name,
      description: r.description,
      status: Status.Active
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "party group created",
            description: `party group: ${r.data?.id}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create party group",
            description: r.data?.description
          });
        }
      });
  };

  const clonePartyGroup = async (
    r: Pick<PartyGroup, "name" | "description">
  ) => {
    return await createPartyGroup(r);
  };

  const handleUpdateBatchApiResponse = async (apiCall) => {
    return await apiCall.then(responseHandler).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Party groups Updated",
          description: `total Party groups updated: ${r.data?.batch?.length}`
        });
      }
      if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to update batch",
          description: r.data?.description
        });
      }
      return r;
    });
  };

  const updatePartyGroupSelectionsBatch = async (
    r: Omit<Partial<PartyGroup>, keyof BaseEntity<object>>
  ) => {
    const shouldUpdateAll = partyGroupState.isAllChecked;

    if (!shouldUpdateAll && partyGroupState.selectedIds) {
      const updatedSelected = partyGroupState.selectedIds.reduce((acc, id) => {
        acc[id] = r;
        return acc;
      }, {} as { [id: string]: Partial<PartyGroup> });
      return await updatePartyGroupBatch(updatedSelected);
    }

    if (shouldUpdateAll) {
      const payload = {
        shouldUpdateAll,
        excludedIds: partyGroupState.excludedIds.map(String),
        updateDetails: r
      };

      return await handleUpdateBatchApiResponse(updateBatchApi(payload));
    }
  };

  const updatePartyGroupBatch = async (r: {
    [id: string]: Partial<PartyGroup>;
  }) => {
    const payload = removeEmptyValues(r);

    if (!payload?.length) {
      return null;
    }

    return await handleUpdateBatchApiResponse(
      updateBatchApi({ partyGroups: payload })
    );
  };

  const dataRank: FieldsWeight<PartyGroup> = {
    name: 30
  };

  const rankedSort = (query: string) => {
    return Object.values(partyGroupState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank);
      const rankB = getRankedData(query, b, dataRank);

      if (rankA < rankB) {
        return 1;
      }
      return -1;
    });
  };

  return {
    countApi,
    getPartyGroup,
    getPartyGroupApiResponse,
    getBatchApi,
    getBatchResponse,
    selectPartyGroup,
    setPartyGroupId,
    unSelectPartyGroup,
    selectAllPartyGroup,
    unSelectAllPartyGroup,
    partyGroupState,
    createPartyGroup,
    createResponse,
    clonePartyGroup,
    updatePartyGroupSelectionsBatch,
    updatePartyGroupBatch,
    updateBatchResponse,
    paginationHandler,
    searchApi,
    searchResponse,
    dataRank,
    rankedSort
  };
};
