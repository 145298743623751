import { Type } from "@sinclair/typebox";
import { StringField } from "./utils.typebox";

export const partyGroupModel = Type.Object({
  name: Type.String({ minLength: 1 }),
  description: Type.Optional(StringField),
  status: Type.Optional(
    Type.Union([Type.Literal("active"), Type.Literal("disabled")])
  )
});

export const partyGroupUpdate = Type.Object({
  id: StringField,
  name: Type.Optional(StringField),
  description: Type.Optional(StringField),
  status: Type.Optional(
    Type.Union([Type.Literal("active"), Type.Literal("disabled")])
  )
});

export const partyGroupBatchUpdate = Type.Object({
  updateDetails: Type.Optional(Type.Partial(partyGroupModel)),
  excludedIds: Type.Optional(Type.Array(Type.String())),
  shouldUpdateAll: Type.Optional(Type.Boolean()),
  partyGroups: Type.Array(partyGroupUpdate)
});
