import { Box, Flex } from "@chakra-ui/react";
import { CalendarProps } from "./Calendar";
import { Day } from "./Day";
import { CalendarDayData } from "./calendar.hooks";

export type MonthProps = {
  days: CalendarDayData[];
} & CalendarProps;

const ROW_LENGTH = 7;

export const Month = ({
  height,
  days,
  onHoverDate,
  BodyComponent,
  FooterComponent,
  DayToolTipLabelComponent
}: MonthProps) => {
  const weekRows: CalendarDayData[][] = [];
  for (let i = 0; i < days.length; i += ROW_LENGTH) {
    weekRows.push(days.slice(i, i + ROW_LENGTH));
  }

  return (
    <Box>
      {weekRows.map((row, rowIndex) => (
        <Flex key={rowIndex} h={height ? height / weekRows.length : "auto"}>
          {row.map(
            (
              { date, currentMonth, isToday, showDayName, monthName },
              index
            ) => (
              <Day
                key={index}
                onHoverDate={onHoverDate}
                currentMonth={currentMonth}
                showDayName={showDayName}
                monthName={monthName}
                isToday={isToday}
                currentDay={date}
                DayToolTipLabelComponent={DayToolTipLabelComponent}
                BodyComponent={BodyComponent}
                FooterComponent={FooterComponent}
              />
            )
          )}
        </Flex>
      ))}
    </Box>
  );
};
