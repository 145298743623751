import { Divider } from "@chakra-ui/react";
import {
  AggregationType,
  DealPartyRelation,
  DealPartyRelationType,
  EntityParty,
  IndividualParty,
  Party,
  PartyRelation,
  PartyRelationType,
  TaxpayerIdentifierType
} from "@elphi/types";
import {
  OverridePartyValidationSettingsIndicatorFieldPath,
  businessIncorporationStateCodeFieldPath,
  businessIncorporationStateNameFieldPath,
  businessRegistrationStatesFieldPath,
  crmIdFieldPath,
  entityFullNameFieldPath,
  entityNotesFieldPath,
  entityTypeFieldPath,
  evidenceOfGoodStandingExpirationDateFieldPath,
  fixAndFlipBridgePlusTierFieldPath,
  newConstructionEligibilityIndicatorFieldPath,
  operationsDepartmentFieldPath,
  partyMilestoneFieldPath,
  realEstateOperationTypeFieldPath,
  representativeCreditScoreFieldPath,
  taxpayerIdentifierTypeFieldPath,
  taxpayerIdentifierValueFieldPath
} from "../field-paths/entity.fieldPaths";
import {
  backgroundReportDateFieldPath,
  bankruptcyIndicatorFieldPath,
  convictionIndicatorFieldPath,
  creditReportNotesFieldPath,
  estimatedCreditScoreFieldPath,
  // creditReportPullTypeFieldPath,
  experienceNotesFieldPath,
  fixNFlipNBridgePlusTierFieldPath,
  flipsAverageProjectDurationFieldPath,
  flipsAverageSalePriceFieldPath,
  flipsCumulativeSalePriceFieldPath,
  foreclosureIndicatorFieldPath,
  individualBirthDateFieldPath,
  individualCitizenshipCountryFieldPath,
  individualContactPointEmailValueFieldPath,
  individualContactPointRoleTypeFieldPath,
  individualContactPointTelephoneValueFieldPath,
  crmIdFieldPath as individualCrmIdFieldPath,
  individualFirstNameFieldPath,
  individualGovernmentIssuedIDExpirationDateFieldPath,
  individualLastNameFieldPath,
  individualMiddleNameFieldPath,
  paymentTokenIdFieldPath as individualPaymentTokenIdFieldPath,
  individualUSCitizenshipIndicatorFieldPath,
  loanAmountInProcessFieldPath,
  locExpirationDateAggregationFieldPath,
  locStatusTypeFieldPath,
  mortgageDelinquentIndicatorFieldPath,
  newBuildsAverageProjectDurationFieldPath,
  newBuildsAverageSalePriceFieldPath,
  newBuildsCumulativeSalePriceFieldPath,
  numberOfFlipsFieldPath,
  numberOfNewBuildsFieldPath,
  numberOfTransactionsFieldPath,
  outstandingJudgmentsIndicatorFieldPath,
  outstandingLawsuitsIndicatorFieldPath,
  statedCashOnHandAmountFieldPath,
  totalApprovedLOCFieldPath,
  transactionsAverageProjectDurationFieldPath,
  transactionsAverageSalePriceFieldPath,
  transactionsCumulativeSalePriceFieldPath
} from "../field-paths/individual.fieldPaths";
import {
  backgroundReportExpirationDateFieldPath,
  groupIdPath,
  partyAddressLineTextFieldPath,
  partyAddressUnitIdentifierFieldPath,
  partyCityNameFieldPath,
  partyCountyNameFieldPath,
  partyPostalCodeFieldPath,
  partyStateCodeFieldPath,
  partyStateNameFieldPath,
  partyTypeFieldPath,
  totalStatementQualifyingBalanceFieldPath
} from "../field-paths/party.fieldPaths";
import {
  ownershipPercentageFieldPath,
  partyRelationRoleTypeFieldPath
} from "../field-paths/partyRelation.fieldPaths";

import { partyValidations } from "@elphi/utils/src/validation.utils";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import {
  dealToEntityFieldSpecs,
  dealToIndividualFieldSpecs
} from "../../form-builder/field-specs/deal-party/deal-party.fields";
import {
  entityToEntityRelationFieldSpecs,
  entityToIndividualRelationFieldSpecs
} from "../../form-builder/field-specs/party-relation/party-relation.fields";
import {
  entityPartyFieldSpecs,
  individualPartyFieldSpecs,
  partyFieldSpecs
} from "../../form-builder/field-specs/party/party.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  einInputFormatter,
  ssnInputFormatter
} from "../../form-builder/formatters/inputs.formatter";
import {
  booleanValidation,
  dateValidation,
  einValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  ssnValidation,
  stringLengthValidation,
  stringValidation
} from "../../utils/validationUtils";
import { relationRoleTypeFieldPath } from "../field-paths/dealParty.fieldPaths";

export const addressSection = (p: {
  state: Partial<Party>;
  title?: string;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const { state, title, onChange } = p;

  return {
    header: title ?? "Address",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: partyFieldSpecs,
      specs: [
        { path: partyAddressLineTextFieldPath, validation: stringValidation },
        {
          path: partyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        { path: partyCityNameFieldPath, validation: stringValidation },
        { path: partyCountyNameFieldPath, validation: stringValidation },
        { path: partyStateNameFieldPath, validation: singleSelectValidation },
        { path: partyStateCodeFieldPath, validation: singleSelectValidation },
        { path: partyPostalCodeFieldPath, validation: stringValidation }
      ]
    })
  };
};
export const individualInfoSection = (
  state: Partial<IndividualParty>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Individual Info",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: individualPartyFieldSpecs,
      specs: [
        { path: individualFirstNameFieldPath, validation: stringValidation },
        { path: individualMiddleNameFieldPath, validation: stringValidation },
        { path: individualLastNameFieldPath, validation: stringValidation },
        { path: individualBirthDateFieldPath, validation: dateValidation },
        {
          path: individualGovernmentIssuedIDExpirationDateFieldPath,
          validation: dateValidation
        },
        {
          path: backgroundReportDateFieldPath,
          validation: dateValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.BackgroundReportExpirationDate?.focused !==
            "override",
          path: backgroundReportExpirationDateFieldPath,
          validation: dateValidation
        },
        {
          path: individualCitizenshipCountryFieldPath,
          validation: stringValidation
        },
        {
          path: individualUSCitizenshipIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: individualContactPointRoleTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: individualContactPointTelephoneValueFieldPath,
          validation: stringValidation
        },
        {
          path: individualContactPointEmailValueFieldPath,
          validation: emailValidation
        },
        {
          path: operationsDepartmentFieldPath,
          validation: singleSelectValidation
        },
        // {
        //   path: individualLiquidAssetTotalAmountFieldPath,
        //   validation: moneyValidation
        // },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.TotalStatementQualifyingBalance?.focused !==
            "override",
          path: totalStatementQualifyingBalanceFieldPath,
          validation: moneyValidation
        },
        {
          path: individualPaymentTokenIdFieldPath,
          validation: stringValidation
        },
        {
          path: individualCrmIdFieldPath,
          validation: stringValidation
        },
        {
          path: creditReportNotesFieldPath,
          validation: stringValidation
        },
        {
          path: locStatusTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: totalApprovedLOCFieldPath,
          validation: moneyValidation
        },
        {
          path: loanAmountInProcessFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.aggregations?.LOCExpirationDate?.focused !== "override",
          path: locExpirationDateAggregationFieldPath,
          validation: dateValidation
        }
      ]
    })
  };
};

export const entitySections = (r: {
  state: Partial<EntityParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    entityTaxIdentifierSection({ state, onChange }),
    {
      header: "Entity Info",
      inputs: buildInputs({
        state,
        onChange,
        fieldSpecs: entityPartyFieldSpecs,
        specs: [
          { path: entityTypeFieldPath, validation: singleSelectValidation },
          {
            path: entityFullNameFieldPath,
            validation: stringLengthValidation(
              partyValidations.fullNameMaxLength
            )
          },
          {
            path: representativeCreditScoreFieldPath,
            validation: numberValidation
          },
          {
            path: businessIncorporationStateNameFieldPath,
            validation: singleSelectValidation
          },
          {
            path: businessIncorporationStateCodeFieldPath,
            validation: singleSelectValidation
          },
          {
            path: businessRegistrationStatesFieldPath,
            validation: multiSelectValidation
          },
          {
            path: fixAndFlipBridgePlusTierFieldPath,
            validation: numberValidation
          },
          {
            path: newConstructionEligibilityIndicatorFieldPath,
            validation: booleanValidation
          },
          {
            path: realEstateOperationTypeFieldPath,
            validation: multiSelectValidation
          },
          // {
          //   path: totalFixAndFlipNewConstructionSoldPastThreeYearsCountFieldPath,
          //   validation: numberValidation
          // },
          {
            path: operationsDepartmentFieldPath,
            validation: singleSelectValidation
          },
          // {
          //   path: liquidAssetTotalAmountFieldPath,
          //   validation: moneyValidation
          // },
          { path: entityNotesFieldPath, validation: stringValidation },
          {
            path: crmIdFieldPath,
            validation: stringValidation
          },
          {
            path: backgroundReportDateFieldPath,
            validation: dateValidation
          },
          {
            isAggregation: AggregationType.Aggregation,
            isReadOnly:
              state?.aggregations?.BackgroundReportExpirationDate?.focused !==
              "override",
            path: backgroundReportExpirationDateFieldPath,
            validation: dateValidation
          },
          {
            path: evidenceOfGoodStandingExpirationDateFieldPath,
            validation: dateValidation
          }
        ]
      })
    },
    addressSection({ state, title: "Entity Address", onChange })
  ];
};

export const individualSections = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    individualTaxIdentifierSection(state, onChange),
    individualInfoSection(state, onChange),
    addressSection({ state, title: "Individual Address", onChange }),
    partyShortTermExperienceSection({ state, onChange })
  ];
};
export const individualPartyStructureSections = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    individualTaxIdentifierSection(state, onChange),
    individualInfoSection(state, onChange),
    addressSection({ state, title: "Individual Address", onChange })
  ];
};

export const individualPartyDeclarationsSections = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    {
      header: "Declarations",
      inputs: buildInputs({
        state,
        onChange,
        fieldSpecs: individualPartyFieldSpecs,
        specs: [
          {
            path: estimatedCreditScoreFieldPath,
            validation: numberValidation
          },
          { path: convictionIndicatorFieldPath, validation: booleanValidation },
          {
            path: outstandingLawsuitsIndicatorFieldPath,
            validation: booleanValidation
          },
          {
            path: outstandingJudgmentsIndicatorFieldPath,
            validation: booleanValidation
          },
          { path: bankruptcyIndicatorFieldPath, validation: booleanValidation },
          {
            path: foreclosureIndicatorFieldPath,
            validation: booleanValidation
          },
          {
            path: mortgageDelinquentIndicatorFieldPath,
            validation: booleanValidation
          },
          {
            path: statedCashOnHandAmountFieldPath,
            validation: booleanValidation
          }
        ]
      })
    }
  ];
};

export const partyMilestoneSection = (r: {
  state: Partial<EntityParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const { state, onChange } = r;
  return {
    header: "Party Milestone",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: entityPartyFieldSpecs,
      specs: [
        { path: partyMilestoneFieldPath, validation: singleSelectValidation }
      ]
    })
  };
};

export const partyInfoSection = (r: {
  state: Partial<Party>;
  onChange?: (v: OnChangeInput) => void;
  options?: { isReadOnly?: boolean; hideAttachedComponent?: boolean };
}): Section => {
  const { state, onChange, options } = r;
  return {
    header: "Party Info",
    inputs: buildInputs({
      state,
      onChange,

      fieldSpecs: partyFieldSpecs,
      specs: [
        {
          path: partyTypeFieldPath,
          validation: singleSelectValidation,
          isReadOnly: options?.isReadOnly
        }
      ],
      hideAttachedComponent: options?.hideAttachedComponent
    })
  };
};

const getTaxIdentifierValidator = (identifierType?: string) => {
  if (!identifierType) {
    return undefined;
  }

  switch (identifierType) {
    case TaxpayerIdentifierType.SSN:
    case TaxpayerIdentifierType.TIN:
      return ssnValidation;
    case TaxpayerIdentifierType.EIN:
      return einValidation;
    default:
      return undefined;
  }
};

const getTaxIdentifierFormatter = (identifierType?: string) => {
  if (!identifierType) {
    return undefined;
  }

  switch (identifierType) {
    case TaxpayerIdentifierType.SSN:
    case TaxpayerIdentifierType.TIN:
      return ssnInputFormatter;
    case TaxpayerIdentifierType.EIN:
      return einInputFormatter;
    default:
      return undefined;
  }
};

export const entityTaxIdentifierSection = (r: {
  state: Partial<EntityParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const { state, onChange } = r;
  return {
    header: "Identifier",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: entityPartyFieldSpecs,
      specs: [
        {
          path: taxpayerIdentifierTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: taxpayerIdentifierValueFieldPath,
          validation: getTaxIdentifierValidator(state?.TaxpayerIdentifierType),
          formatter: getTaxIdentifierFormatter(state?.TaxpayerIdentifierType)
        },
        {
          path: groupIdPath,
          validation: singleSelectValidation
        },
        {
          path: OverridePartyValidationSettingsIndicatorFieldPath,
          validation: singleSelectValidation
        }
      ]
    })
  };
};
export const individualTaxIdentifierSection = (
  state: Partial<IndividualParty>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Identifier",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: individualPartyFieldSpecs,
      specs: [
        {
          path: taxpayerIdentifierTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          path: taxpayerIdentifierValueFieldPath,
          validation: getTaxIdentifierValidator(state?.TaxpayerIdentifierType),
          formatter: getTaxIdentifierFormatter(state?.TaxpayerIdentifierType)
        },
        {
          path: groupIdPath,
          validation: singleSelectValidation
        }
      ]
    })
  };
};
export const createEntitySection = (r: {
  state: Partial<EntityParty>;
  onChange?: (v: OnChangeInput) => void;
  options: { hideAttachedComponent: boolean };
}): Section => {
  const { state, onChange, options } = r;
  return {
    header: <Divider />,
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: entityPartyFieldSpecs,
      specs: [
        {
          path: entityFullNameFieldPath,
          validation: stringLengthValidation(partyValidations.fullNameMaxLength)
        },
        {
          path: taxpayerIdentifierValueFieldPath,
          validation: einValidation,
          formatter: einInputFormatter
        }
      ],
      hideAttachedComponent: options.hideAttachedComponent
    })
  };
};
export const createIndividualSection = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
  options: { hideAttachedComponent: boolean };
}): Section => {
  const { state, onChange, options } = r;
  return {
    header: <Divider />,
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: individualPartyFieldSpecs,
      specs: [
        { path: individualFirstNameFieldPath, validation: stringValidation },
        { path: individualMiddleNameFieldPath, validation: stringValidation },
        { path: individualLastNameFieldPath, validation: stringValidation },
        {
          path: taxpayerIdentifierValueFieldPath,
          validation: ssnValidation,
          formatter: ssnInputFormatter
        }
      ],
      hideAttachedComponent: options.hideAttachedComponent
    })
  };
};

export const partyProfileSections = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    {
      header: "Individual Info",
      inputs: buildInputs({
        state,
        onChange,
        fieldSpecs: individualPartyFieldSpecs,
        specs: [
          { path: creditReportNotesFieldPath, validation: stringValidation },
          // {
          //   path: creditReportPullTypeFieldPath,
          //   validation: singleSelectValidation
          // },
          {
            path: fixNFlipNBridgePlusTierFieldPath,
            validation: numberValidation
          },
          {
            path: newConstructionEligibilityIndicatorFieldPath,
            validation: booleanValidation
          },
          { path: locStatusTypeFieldPath, validation: singleSelectValidation },
          { path: totalApprovedLOCFieldPath, validation: moneyValidation },
          { path: loanAmountInProcessFieldPath, validation: moneyValidation },
          {
            isAggregation: AggregationType.Aggregation,
            isReadOnly:
              state?.aggregations?.LOCExpirationDate?.focused !== "override",
            path: locExpirationDateAggregationFieldPath,
            validation: dateValidation
          },
          { path: backgroundReportDateFieldPath, validation: dateValidation },
          {
            path: individualPaymentTokenIdFieldPath,
            validation: stringValidation
          },
          { path: individualCrmIdFieldPath, validation: stringValidation }
        ]
      })
    }
  ];
};

export const partyProfileSectionsAfterTable = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [partyShortTermExperienceSection({ state, onChange })];
};

export const partyRelationSection = (r: {
  state: PartyRelation;
  onChange?: (v: OnChangeInput) => void;
  options?: { hideAttachedComponent: boolean; isReadOnly?: boolean };
}): Section => {
  const { state, onChange, options } = r;
  const type = state?.type;
  const fieldSpecs =
    type === PartyRelationType.EntityToEntity
      ? entityToEntityRelationFieldSpecs
      : entityToIndividualRelationFieldSpecs;

  return {
    header: "Relation",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs,
      specs: [
        {
          path: ownershipPercentageFieldPath,
          validation: percentValidation,
          isReadOnly: r.options?.isReadOnly
        },
        {
          path: partyRelationRoleTypeFieldPath,
          validation: multiSelectValidation,
          isReadOnly: r.options?.isReadOnly
        }
      ],
      hideAttachedComponent: options?.hideAttachedComponent
    })
  };
};

export const dealPartyRelationSection = (r: {
  state: DealPartyRelation;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const { state, onChange } = r;
  const type = state?.type;
  const fieldSpecs =
    type === DealPartyRelationType.DealEntity
      ? dealToEntityFieldSpecs
      : dealToIndividualFieldSpecs;
  return {
    header: "Deal-Party Relationship",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs,
      specs: [
        { path: relationRoleTypeFieldPath, validation: multiSelectValidation }
      ]
    })
  };
};

export const partyShortTermExperienceSection = (r: {
  state: Partial<IndividualParty>;
  onChange?: (v: OnChangeInput) => void;
}): Section => {
  const { state, onChange } = r;
  return {
    header: "Short-Term Experience",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: individualPartyFieldSpecs,
      specs: [
        { path: numberOfTransactionsFieldPath, validation: numberValidation },
        {
          path: transactionsCumulativeSalePriceFieldPath,
          validation: moneyValidation
        },
        {
          path: transactionsAverageProjectDurationFieldPath,
          validation: numberValidation
        },
        {
          path: transactionsAverageSalePriceFieldPath,
          validation: moneyValidation
        },
        { path: numberOfFlipsFieldPath, validation: numberValidation },
        {
          path: flipsCumulativeSalePriceFieldPath,
          validation: moneyValidation
        },
        {
          path: flipsAverageProjectDurationFieldPath,
          validation: numberValidation
        },
        { path: flipsAverageSalePriceFieldPath, validation: moneyValidation },
        { path: numberOfNewBuildsFieldPath, validation: numberValidation },
        {
          path: newBuildsCumulativeSalePriceFieldPath,
          validation: moneyValidation
        },
        {
          path: newBuildsAverageProjectDurationFieldPath,
          validation: numberValidation
        },
        {
          path: newBuildsAverageSalePriceFieldPath,
          validation: moneyValidation
        },
        { path: experienceNotesFieldPath, validation: stringValidation },
        {
          path: fixNFlipNBridgePlusTierFieldPath,
          validation: numberValidation
        },
        {
          path: newConstructionEligibilityIndicatorFieldPath,
          validation: booleanValidation
        }
      ]
    })
  };
};
