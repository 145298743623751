import {
  DATES_FORMATS,
  MAX_SHOW_DEAL_CALENDARS,
  elphiDate
} from "@elphi/utils";
import { useEffect } from "react";
import { Calendar } from "../../common-components/calendar";
import { DateRange } from "../../common-components/calendar/Calendar";
import useDealHooks from "../../hooks/deal.hooks";
import { useDealCalendarHooks } from "../../hooks/dealCalendar.hooks";
import useWindowDimensions from "../../hooks/windowDimensions";
import { DaysToolTipLabel } from "./deal-calendar-day/DaysToolTipLabel";
import { DealCalendarDay } from "./deal-calendar-day/DealCalendarDay";
import { DealCalendarFooterDay } from "./deal-calendar-day/DealCalendarFooterDay";
export type DealCalendarProps = {};

const OFFSET_HEIGHT = 200;

export const DealCalendar = ({}: DealCalendarProps) => {
  const {
    selectedCalendarViewDate,
    dates,
    selectedDateType: dateType,
    calendarCurrentViewDates,
    setCalendarCurrentViewDates
  } = useDealCalendarHooks();
  const { dealTableFilter } = useDealHooks();

  const { getDateRangeApi, setHoverDate } = useDealCalendarHooks();
  const { heightOffset } = useWindowDimensions();

  const onInitMonth = async ({ startDate, endDate }: DateRange) => {
    setCalendarCurrentViewDates({
      startDate,
      endDate
    });
    const from = elphiDate(startDate).format(DATES_FORMATS.YYYY_MM_DD);
    const to = elphiDate(endDate).format(DATES_FORMATS.YYYY_MM_DD);
    await getDateRangeApi({ from, to, dateType, filters: dealTableFilter });
  };

  useEffect(() => {
    const { startDate, endDate } = calendarCurrentViewDates;
    !!startDate && !!endDate && onInitMonth({ startDate, endDate });
  }, [dateType, dealTableFilter]);

  const onHoverDate = async (currentDay: Date) => {
    const from = elphiDate(currentDay).format(DATES_FORMATS.YYYY_MM_DD);
    const dateDay = dates?.[dateType]?.[from] || {};
    if (
      dateDay.totalDealsCalendar > MAX_SHOW_DEAL_CALENDARS &&
      dateDay.totalDealsCalendar !== dateDay.deals.length
    ) {
      await getDateRangeApi({ from, dateType, filters: dealTableFilter });
    }

    setHoverDate({ hoverDate: currentDay });
  };

  return (
    <Calendar
      {...selectedCalendarViewDate}
      height={heightOffset(OFFSET_HEIGHT)}
      BodyComponent={DealCalendarDay}
      FooterComponent={DealCalendarFooterDay}
      DayToolTipLabelComponent={DaysToolTipLabel}
      onHoverDate={onHoverDate}
      onInitMonth={onInitMonth}
      startWeekInMonday
    />
  );
};
