import { Box, Text } from "@chakra-ui/react";
import { EMPTY } from "../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../hooks/rolodexConfiguration.hooks";
import { ConfigurationSearch } from "../../../configuration/tabs/service-domain-configuration/search/ConfigurationSearch";
export const DomainStepContent = () => {
  const { setSelectedConfiguration, selectedConfiguration } =
    useRolodexConfiguration();
  return (
    <Box>
      <Text pl={"3px"} pb={"3px"} fontWeight={"semibold"}>
        Select Service Domain
      </Text>
      <ConfigurationSearch
        currentValue={selectedConfiguration ? selectedConfiguration.id : EMPTY}
        onSelect={setSelectedConfiguration}
        filter={(x) => x.status === "active"}
      />
    </Box>
  );
};
