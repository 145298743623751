import { Box, Button, Flex, HStack, Tooltip } from "@chakra-ui/react";
import {
  Deal,
  DealMilestoneType,
  FieldType,
  LOSUser,
  LenderIdentifierType
} from "@elphi/types";
import { FeatureFlag } from "@elphi/utils";
import { isEmpty } from "lodash";
import { ReactNode, useMemo, useState } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { FilterMenuOptions } from "../../common-components/menu-options/FilterMenuOptions";

import { useFilterMenuHook } from "../../common-components/menu-options/filter-menu-hook";
import { GateKeeper } from "../../features/gate/GateKeeper";
import useDealHooks from "../../hooks/deal.hooks";
import { useLOSUserHooks } from "../../hooks/losuser.hooks";
import { DealPageViews } from "../../redux/v2/deal";
import { DealCalendar } from "../deal-calendar/DealCalendar";
import StyledInputBuilder from "../form-builder/InputBuilder";
import { createOptionsFromEnum } from "../utils/formUtils";
import { CreateDealModal } from "./CreateDealComponent";
import { DealPageMenu } from "./DealPageMenu";
import DealSearch from "./DealSearch";
import DealTableContainer from "./DealTableContainerV2";

const buildOption = (losUser: LOSUser) => {
  return {
    label: `${losUser.name} (${losUser.email})`,
    value: losUser.id
  };
};

export const DealPage = (_: { deal?: Deal }) => {
  const {
    setSelectedDeal,
    selectedDeal,
    setDealTableFilter,
    dealTableFilter,
    selectedDealPageView
  } = useDealHooks();
  const filterMenuHook = useFilterMenuHook();
  const { clearFilters, filters } = filterMenuHook;
  const { losUserState } = useLOSUserHooks();
  const [myDeals, setMyDeals] = useState(false);
  const toggleMyDeals = () => setMyDeals(!myDeals);

  const usersOptions = useMemo(() => {
    return Object.values(losUserState.entities).map(buildOption);
  }, [losUserState]);

  const PageViews: { [key in DealPageViews]: ReactNode } = {
    calendar: <DealCalendar />,
    list: <DealTableContainer myDealsFlag={myDeals} />
  };

  const selectedFilterValues = {
    selectedUser: dealTableFilter?.selectedUserId || "",
    selectedLenders: dealTableFilter?.lenderIdentifier || [],
    selectedMilestones: dealTableFilter?.dealMilestone || [],
    selectedDateRage: dealTableFilter?.estimatedClosingDateRange || {}
  };

  const clearAll = () => {
    clearFilters();
    setDealTableFilter({
      ...dealTableFilter,
      selectedUserId: "",
      lenderIdentifier: [],
      dealMilestone: [],
      estimatedClosingDateRange: {},
      dealMilestoneOp: "in",
      lenderIdentifierOp: "in"
    });
  };

  const onChangeOp = (op) => {
    setDealTableFilter({
      ...dealTableFilter,
      dealMilestoneOp: op,
      lenderIdentifierOp: op
    });
  };
  const isUserSelected = !!filters?.["dealPageUserFilter"]?.length;

  const handleChange = (e) => {
    const { target } = e;
    setDealTableFilter({
      ...dealTableFilter,
      estimatedClosingDateRange: target?.value || {}
    });
  };

  const {
    selectedMilestones,
    selectedLenders,
    selectedUser,
    selectedDateRage
  } = selectedFilterValues;

  return (
    <Box p="10px">
      <Flex justifyContent="space-between" wrap={"wrap"}>
        <Box>
          <HStack spacing={2} wrap="wrap">
            <Box w={"380px"}>
              <DealSearch
                currentValue={(selectedDeal && selectedDeal.id) || ""}
                onSelect={setSelectedDeal}
                label={"search deals"}
                labelPosition={"placeHolder"}
              />
            </Box>
            <Tooltip
              isDisabled={!isUserSelected}
              aria-label={`toggleMyDeals-tooltip`}
              label={"Clear user filter to enable this one"}
            >
              <Box alignSelf="start">
                <Button
                  {...elphiTheme.components.light.button[
                    myDeals ? "generate" : "primary"
                  ]}
                  isDisabled={isUserSelected}
                  onClick={toggleMyDeals}
                  mt={"10px"}
                >
                  {myDeals ? "Go to All Deals" : "Go to My Deals"}
                </Button>
              </Box>
            </Tooltip>
            <DealPageMenu />
          </HStack>
        </Box>
        <Box p="10px">
          <CreateDealModal />
        </Box>
      </Flex>
      <Flex>
        <Box>
          <FilterMenuOptions
            isDisabled={myDeals}
            customKey="dealPageUserFilter"
            buttonTooltipLabel={myDeals ? "remove my deals filter" : ""}
            filterMenuHook={filterMenuHook}
            title="User"
            onChange={(items: string[]) => {
              setDealTableFilter({
                ...dealTableFilter,
                selectedUserId: items[0]
              });
            }}
            options={usersOptions}
            selected={selectedUser ? [selectedUser] : undefined}
            showSearchBar
            isSingle
          />
        </Box>

        <Box>
          <Box pl="5px">
            <FilterMenuOptions
              customKey="dealPageLenderFilter"
              filterMenuHook={filterMenuHook}
              buttonTooltipLabel={`${dealTableFilter?.lenderIdentifier?.join(
                ","
              )}`}
              title="Lender"
              onChange={(items: LenderIdentifierType[]) => {
                setDealTableFilter({
                  ...dealTableFilter,
                  lenderIdentifier: items
                });
              }}
              onChangeOp={onChangeOp}
              options={[
                ...createOptionsFromEnum(LenderIdentifierType),
                { label: "Missing Lender", value: "" }
              ]}
              selected={selectedLenders}
              selectedOp={dealTableFilter?.lenderIdentifierOp}
              showSearchBar
              showSelectClearAll
            />
          </Box>
        </Box>
        <Box>
          <Box pl="5px">
            <FilterMenuOptions
              customKey="dealPageMilestoneFilter"
              filterMenuHook={filterMenuHook}
              title="Milestone"
              buttonTooltipLabel={`${dealTableFilter?.dealMilestone?.join(
                ","
              )}`}
              onChange={(items: DealMilestoneType[]) => {
                setDealTableFilter({
                  ...dealTableFilter,
                  dealMilestone: items
                });
              }}
              onChangeOp={onChangeOp}
              options={createOptionsFromEnum(DealMilestoneType)}
              selected={selectedMilestones}
              selectedOp={dealTableFilter?.dealMilestoneOp}
              showSearchBar
              showSelectClearAll
            />
          </Box>
        </Box>
        <Box pl="5px">
          <GateKeeper gate={FeatureFlag.ESD_2846_Closing_Date_Filter}>
            {selectedDealPageView !== "calendar" && (
              <StyledInputBuilder
                currentValue={dealTableFilter?.estimatedClosingDateRange}
                fieldType={FieldType.DateRange}
                label={"Closing Date"}
                onChange={handleChange}
              />
            )}
          </GateKeeper>
        </Box>
        <Box pl="5px">
          <Button
            disabled={
              isEmpty(selectedMilestones) &&
              isEmpty(selectedLenders) &&
              isEmpty(selectedUser) &&
              isEmpty(selectedDateRage)
            }
            bgColor={"transparent"}
            fontWeight={"medium"}
            fontSize={14}
            onClick={clearAll}
          >
            Clear filters
          </Button>
        </Box>
      </Flex>
      <Box overflowX="auto">{PageViews?.[selectedDealPageView]}</Box>
    </Box>
  );
};
//

export default DealPage;
