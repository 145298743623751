import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Collapse,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  PartyType,
  RolodexServiceProviderView,
  ServiceProviderEntityType,
  ServiceProviderStatusType
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import React from "react";
import { EMPTY, NOT_AVAILABLE } from "../../../../../constants/common";
import { useServiceProviderHooks } from "../../../../../hooks/rolodexServiceProvider.hooks";
import {
  MaximizeIcon,
  MinimizeIcon,
  MoreHorizontalIcon
} from "../../../../icons";
import { RolodexServiceProviderCard } from "../serviceProviderCard.types";
import {
  COLLAPSE_HEIGHT,
  SERVICE_PROVIDER_STATUS_BG_COLOR,
  SERVICE_PROVIDER_STATUS_COLOR_TEXT,
  entityTypeIconMap,
  entityTypeTextMap
} from "./utils.ts/card.utils";

export const ServiceProviderCard = (props: {
  data: RolodexServiceProviderCard;
  onToggleRemove: () => void;
  onToggleEdit: () => void;
}) => {
  const { data, onToggleRemove, onToggleEdit } = props;
  const { dealEntities } = data;

  const { isOpen, onToggle } = useDisclosure();
  const { getDealEntitiesMap, selectedDeal } = useServiceProviderHooks();
  if (!selectedDeal || !dealEntities) {
    return <></>;
  }
  const selectedDealEntities = dealEntities[selectedDeal.id];
  if (!selectedDealEntities?.entityIds) {
    return <></>;
  }
  const dealEntitiesMap = getDealEntitiesMap(data.entityType);
  const entities = selectedDealEntities.entityIds
    .map((e) => {
      return dealEntitiesMap[e];
    })
    .filter(removeEmpty);

  const collapseHeight =
    entities.length === 0
      ? COLLAPSE_HEIGHT.No_Entity
      : entities.length === 1
      ? COLLAPSE_HEIGHT.One_Entity
      : COLLAPSE_HEIGHT.More_Then_One_Entity;

  return (
    <Box
      borderWidth={1}
      borderRadius={"8px"}
      boxShadow={"md"}
      p={2}
      width={400}
      overflow={"auto"}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <Heading as={"h4"} size="md">
          {data.domainConfigurationName}
        </Heading>
        <Flex>
          <ExpandClick isOpen={isOpen} onToggle={onToggle} />
          <MoreOptionsMenuClick
            onToggleRemove={onToggleRemove}
            onToggleEdit={onToggleEdit}
          />
        </Flex>
      </Flex>
      <Collapse in={!isOpen}>
        <MinimizedInfo data={{ ...data }}>
          <EntityIcon entityType={data.entityType} entities={entities} />
        </MinimizedInfo>
      </Collapse>
      <Collapse
        in={isOpen}
        endingHeight={collapseHeight}
        style={{ overflowY: "auto" }}
      >
        <MaximizedProviderInfo
          title={"Company"}
          name={data.companyName}
          status={data.companyStatus}
        />
        <MaximizedProviderInfo
          title={"Branch"}
          name={data.branchName}
          status={data.branchStatus}
        />
        <MaximizedProviderInfo
          title={"Representative"}
          name={data.repName}
          status={data.repStatus}
        />
        {entities.map((e, i) => {
          return (
            <MaximizedEntityInfo
              key={i}
              title={`${entityTypeTextMap[data.entityType || EMPTY]} ${
                entities.length > 1 ? i + 1 : EMPTY
              }`}
              name={e.text}
            />
          );
        })}
      </Collapse>
    </Box>
  );
};

const ExpandClick = (props: { isOpen: boolean; onToggle: () => void }) => {
  return (
    <IconButton
      minW={"unset"}
      bgColor={"transparent"}
      size="sm"
      aria-label=""
      variant="unstyled"
      icon={
        !props.isOpen ? (
          <MaximizeIcon w={5} h={5} />
        ) : (
          <MinimizeIcon w={5} h={5} />
        )
      }
      onClick={() => {
        props.onToggle();
      }}
    />
  );
};

const MoreOptionsMenuClick = (props: {
  onToggleRemove: () => void;
  onToggleEdit: () => void;
}) => {
  return (
    <Menu placement={"bottom-start"}>
      <MenuButton
        ml={1}
        minW={"unset"}
        as={IconButton}
        aria-label=""
        variant="unstyled"
        icon={<MoreHorizontalIcon w={6} h={6} />}
        border={"none"}
        size="sm"
      />
      <MenuList>
        <MenuItem onClick={props.onToggleEdit} icon={<EditIcon w={4} h={4} />}>
          Edit Service Provider
        </MenuItem>
        <MenuItem
          onClick={props.onToggleRemove}
          icon={<DeleteIcon w={4} h={4} />}
        >
          Remove Service Provider
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const MinimizedInfo = (
  props: {
    data: Pick<
      RolodexServiceProviderView,
      "companyName" | "branchName" | "repName" | "repSkipped"
    >;
  } & { children: React.ReactNode }
) => {
  const { data, children } = props;

  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <Flex fontSize="14px" fontWeight={"600"}>
        <Text color={"gray.600"}>{data.companyName}</Text>
        <Text mx={1}>|</Text>
        <Text color={"gray.600"}>{data.branchName}</Text>
        <Text mx={1}>|</Text>
        <Text color={data.repSkipped ? "gray.400" : "gray.600"}>
          {data.repName}
        </Text>
      </Flex>
      <Flex>{children}</Flex>
    </Flex>
  );
};

const MaximizedInfo = (props: { title: string; name: string }) => {
  return (
    <Box>
      <Text color={"gray.500"} fontSize="14px" fontWeight={"500"}>
        {props.title}
      </Text>
      <Text color={"black"} fontSize="14px" fontWeight={"600"}>
        {props.name}
      </Text>
    </Box>
  );
};

const MaximizedEntityInfo = (props: { title: string; name: string }) => {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb={1}>
      <MaximizedInfo {...props} />
    </Flex>
  );
};

const MaximizedProviderInfo = (props: {
  title: string;
  name: string;
  status: ServiceProviderStatusType;
}) => {
  return (
    <Flex justifyContent={"space-between"} alignItems={"center"} mb={1}>
      <MaximizedInfo {...props} />
      <Badge
        fontSize={"12px"}
        fontWeight={"700"}
        color={SERVICE_PROVIDER_STATUS_COLOR_TEXT[props.status || EMPTY]}
        bgColor={SERVICE_PROVIDER_STATUS_BG_COLOR[props.status || EMPTY]}
      >
        {props.status || NOT_AVAILABLE}
      </Badge>
    </Flex>
  );
};

const EntityIcon = (props: {
  entityType: ServiceProviderEntityType;
  entities: {
    id: string;
    text: string;
    type?: PartyType;
  }[];
}) => {
  const { entityType, entities } = props;
  const entityTypeIcon = entityTypeIconMap[entityType];
  const entityValues = Object.values(entities);

  return (
    <>
      {entityType === ServiceProviderEntityType.Party && (
        <>
          {entityTypeIcon?.(PartyType.Individual)}
          <EntityCount
            count={
              entityValues.filter((x) => x?.type === PartyType.Individual)
                .length
            }
          />
          {entityTypeIcon?.(PartyType.Entity)}
          <EntityCount
            count={
              entityValues.filter((x) => x?.type === PartyType.Entity).length
            }
          />
        </>
      )}
      {entityType !== ServiceProviderEntityType.Party && (
        <>
          {entityTypeIcon?.()}
          <EntityCount count={entityValues.length} />
        </>
      )}
    </>
  );
};

const EntityCount = (props: { count: number }) => {
  return (
    <Text fontWeight={"bold"} mx={1} pt={"2px"}>
      {props.count}
    </Text>
  );
};
