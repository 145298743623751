import { Deal, DealCalendarType } from "@elphi/types";
import { checkIncludes, checkIncludesArray } from "../object.utils";

type DealCalendarFilter = {
  userId?: string;
  milestoneOp?: "in" | "not-in";
  milestone?: Deal["DealMetadata"]["milestone"][];
  lenderOp?: "in" | "not-in";
  lender?: Deal["LenderIdentifier"][];
  estimatedClosingDateRange?: string[];
};

export const filterDealCalendar = (
  dealCalendar: DealCalendarType,
  filters: DealCalendarFilter
) => {
  const {
    lenderOp = "in",
    lender,
    milestoneOp = "in",
    milestone,
    userId
  } = filters || {};

  const lenderIdentifierIncludes = checkIncludes(
    lender,
    dealCalendar.LenderIdentifier,
    lenderOp
  );

  const dealMilestoneIncludes = checkIncludes(
    milestone,
    dealCalendar.milestone,
    milestoneOp
  );

  const selectedUserIdIncludes = checkIncludesArray(
    userId ? [userId] : [],
    dealCalendar.assignedUsers || [],
    "in"
  );

  return (
    lenderIdentifierIncludes && dealMilestoneIncludes && selectedUserIdIncludes
  );
};
