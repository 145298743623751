import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  RolodexServiceProvider,
  ServiceProviderStatusType,
  ServiceProviderType
} from "../rolodex";

export const getServiceProviderTemplate = (
  type: ServiceProviderType
): RolodexServiceProvider => {
  if (type === ServiceProviderType.Company) {
    return getCompanyProviderTemplate();
  }
  if (type === ServiceProviderType.Branch) {
    return getBranchProviderTemplate();
  }
  if (type === ServiceProviderType.Representative) {
    return getRepProviderTemplate();
  }
  throw new Error("getServiceProviderTemplate type not supported");
};

const getCompanyProviderTemplate = (): CompanyServiceProvider => {
  return {
    type: ServiceProviderType.Company,
    id: "",
    createdAt: "",
    domainConfigurationId: "",
    index: "",
    modifiedAt: "",
    name: "",
    partyIds: [],
    status: "" as ServiceProviderStatusType
  };
};
const getBranchProviderTemplate = (): BranchServiceProvider => {
  return {
    type: ServiceProviderType.Branch,
    id: "",
    createdAt: "",
    companyId: "",
    index: "",
    modifiedAt: "",
    name: "",
    partyIds: [],
    status: "" as ServiceProviderStatusType
  };
};
const getRepProviderTemplate = (): RepresentativeServiceProvider => {
  return {
    type: ServiceProviderType.Representative,
    id: "",
    createdAt: "",
    companyId: "",
    index: "",
    modifiedAt: "",
    partyIds: [],
    firstName: "",
    lastName: "",
    businessRegistrationStates: [],
    status: "" as ServiceProviderStatusType
  };
};
