import { DeleteIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Box, Checkbox, Flex } from "@chakra-ui/react";
import { FieldType, Status } from "@elphi/types";
import { useState } from "react";
import MenuComponent from "../../../../common-components/menu-options/Menu";
import { usePartyGroup } from "../../../../hooks/partyGroup.hooks";
import StyledInputBuilder from "../../../form-builder/InputBuilder";

export const BulkOperations = () => {
  const [isChecked, setIsChecked] = useState(Status.Disabled);
  const {
    partyGroupState,
    selectAllPartyGroup,
    unSelectAllPartyGroup,
    updatePartyGroupSelectionsBatch
  } = usePartyGroup();

  const { totalPartyGroups, isAllChecked, selectedIds, excludedIds } =
    partyGroupState;
  const selectedPartyGroups = isAllChecked
    ? totalPartyGroups - excludedIds.length
    : selectedIds.length;

  const getSelectedCount = () => {
    return `(${selectedPartyGroups}/${totalPartyGroups})`;
  };

  const isIndeterminateCheck = isAllChecked && !!excludedIds.length;
  const handeCheck = (isChecked) => {
    isChecked && !isIndeterminateCheck
      ? selectAllPartyGroup()
      : unSelectAllPartyGroup();
  };

  const updatePartyGroupActivity = async () => {
    const status =
      isChecked === Status.Disabled ? Status.Active : Status.Disabled;
    await updatePartyGroupSelectionsBatch({ status });
    setIsChecked(status);
  };

  return (
    <Flex p="5px 10px" alignItems={"center"} gap={"8px"}>
      <Checkbox
        isChecked={isAllChecked && !excludedIds.length}
        isIndeterminate={isIndeterminateCheck}
        onChange={(e) => handeCheck(e.target.checked)}
      />
      <Box fontWeight={"400"}>Check All {getSelectedCount()}</Box>
      <MenuComponent
        icon={<HamburgerIcon />}
        items={[
          {
            inputBuilder: (
              <StyledInputBuilder
                currentValue={isChecked}
                fieldType={FieldType.Switch}
                isDisabled={!partyGroupState.selectedIds.length}
                onChange={updatePartyGroupActivity}
              />
            ),
            label: `Make ${
              isChecked === Status.Active ? "unavailable" : "available"
            }`,
            stayOpenAfterSelect: true
          },
          {
            label: "Delete",
            icon: <DeleteIcon />,
            isDisabled: true,
            tooltipText: "Not supported yet"
          }
        ]}
      />
    </Flex>
  );
};
