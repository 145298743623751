import { Flex, Text } from "@chakra-ui/react";
import { DealCalendarType } from "@elphi/types";
import {
  DATES_FORMATS,
  MAX_SHOW_DEAL_CALENDARS,
  elphiDate
} from "@elphi/utils";
import { useEffect, useState } from "react";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";
import { HandHouseBlue100Icon } from "../../icons";
export type DealCalendarDayProps = { currentDay: Date };

export const DealCalendarDay = ({ currentDay }: DealCalendarDayProps) => {
  const { dates, selectedDateType } = useDealCalendarHooks();

  const currentDate = elphiDate(currentDay).format(DATES_FORMATS.YYYY_MM_DD);

  const [currentDayDeals, setCurrentDayDeals] = useState(
    dates?.[selectedDateType]?.[currentDate]?.deals || []
  );

  useEffect(() => {
    setCurrentDayDeals(dates?.[selectedDateType]?.[currentDate]?.deals || []);
  }, [dates?.[selectedDateType]?.[currentDate]]);

  const getBorrowers = (deal: DealCalendarType) =>
    deal?.primaryBorrowers
      ? Object.values(deal?.primaryBorrowers || {})
      : Object.values(deal?.secondaryBorrowers || {});

  return (
    <Flex
      flexDir="column"
      w="100%"
      h="100%"
      justifyContent="space-evenly"
      minH={19 * MAX_SHOW_DEAL_CALENDARS}
      mb="2px"
    >
      {currentDayDeals?.slice(0, MAX_SHOW_DEAL_CALENDARS)?.map((deal, key) => (
        <Flex
          key={`${deal.LoanIdentifier}-${key}`}
          flexDir="row"
          justifyContent="space-between"
          w="100%"
          h="18px"
          borderRadius="10px"
          bgColor="gray.100"
          marginTop="4px"
          px="4px"
        >
          <Flex>
            <HandHouseBlue100Icon w={"18px"} h={"18px"} ml="-3px" />
            <Text>{deal.LoanIdentifier}</Text>
          </Flex>
          <Flex pl={"3px"} overflow={"hidden"} minW={0}>
            <Text textOverflow={"ellipsis"} textAlign={"end"}>
              {getBorrowers(deal)?.map((borrower) => `${borrower.FullName} `)}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
