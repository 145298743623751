import { IndividualParty } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const individualFirstNameFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["FirstName"]
> = ["FirstName"];

export const individualMiddleNameFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["MiddleName"]
> = ["MiddleName"];

export const individualLastNameFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["LastName"]
> = ["LastName"];

export const individualBirthDateFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["BirthDate"]
> = ["BirthDate"];

export const individualGovernmentIssuedIDExpirationDateFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["GovernmentIssuedIDExpirationDate"]
> = ["GovernmentIssuedIDExpirationDate"];

export const individualCitizenshipCountryFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["CitizenshipCountry"]
> = ["CitizenshipCountry"];

export const individualUSCitizenshipIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["USCitizenshipIndicator"]
> = ["USCitizenshipIndicator"];

export const individualContactPointRoleTypeFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["ContactPointRoleType"]
> = ["ContactPointRoleType"];

export const individualContactPointTelephoneValueFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["ContactPointTelephoneValue"]
> = ["ContactPointTelephoneValue"];

export const individualContactPointEmailValueFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["ContactPointEmailValue"]
> = ["ContactPointEmailValue"];

export const individualLiquidAssetTotalAmountFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["LiquidAssetTotalAmount"]
> = ["LiquidAssetTotalAmount"];
export const operationsDepartmentFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["OperationsDepartment"]
> = ["OperationsDepartment"];

export const crmIdFieldPath: ObjectKeyValidation<IndividualParty, ["CRMId"]> = [
  "CRMId"
];

export const paymentTokenIdFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["PaymentTokenId"]
> = ["PaymentTokenId"];

export const creditReportNotesFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["CreditReportNotes"]
> = ["CreditReportNotes"];

export const creditReportDateFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["CreditReportDate"]
> = ["CreditReportDate"];

export const creditReportPullTypeFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["CreditReportPullType"]
> = ["CreditReportPullType"];
export const backgroundReportDateFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["BackgroundReportDate"]
> = ["BackgroundReportDate"];
export const fixNFlipNBridgePlusTierFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["FixNFlipNBridgePlusTier"]
> = ["FixNFlipNBridgePlusTier"];

export const newConstructionEligibilityIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NewConstructionEligibilityIndicator"]
> = ["NewConstructionEligibilityIndicator"];

export const locStatusTypeFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["LOCStatusType"]
> = ["LOCStatusType"];

export const totalApprovedLOCFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["TotalApprovedLOC"]
> = ["TotalApprovedLOC"];

export const unpaidPrincipalBalanceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["UnpaidPrincipalBalance"]
> = ["UnpaidPrincipalBalance"];

export const loanAmountInProcessFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["LoanAmountInProcess"]
> = ["LoanAmountInProcess"];

export const remainingLOCAvailableFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["RemainingLOCAvailable"]
> = ["RemainingLOCAvailable"];

export const numberOfTransactionsFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NumberOfTransactions"]
> = ["NumberOfTransactions"];

export const transactionsCumulativeSalePriceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["TransactionsCumulativeSalePrice"]
> = ["TransactionsCumulativeSalePrice"];

export const transactionsAverageProjectDurationFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["TransactionsAverageProjectDuration"]
> = ["TransactionsAverageProjectDuration"];

export const transactionsAverageSalePriceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["TransactionsAverageSalePrice"]
> = ["TransactionsAverageSalePrice"];

export const numberOfFlipsFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NumberOfFlips"]
> = ["NumberOfFlips"];

export const flipsCumulativeSalePriceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["FlipsCumulativeSalePrice"]
> = ["FlipsCumulativeSalePrice"];

export const flipsAverageProjectDurationFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["FlipsAverageProjectDuration"]
> = ["FlipsAverageProjectDuration"];

export const flipsAverageSalePriceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["FlipsAverageSalePrice"]
> = ["FlipsAverageSalePrice"];

export const numberOfNewBuildsFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NumberOfNewBuilds"]
> = ["NumberOfNewBuilds"];

export const newBuildsCumulativeSalePriceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NewBuildsCumulativeSalePrice"]
> = ["NewBuildsCumulativeSalePrice"];

export const newBuildsAverageProjectDurationFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NewBuildsAverageProjectDuration"]
> = ["NewBuildsAverageProjectDuration"];

export const newBuildsAverageSalePriceFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["NewBuildsAverageSalePrice"]
> = ["NewBuildsAverageSalePrice"];

export const experienceNotesFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["ExperienceNotes"]
> = ["ExperienceNotes"];

export const estimatedCreditScoreFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["EstimatedCreditScore"]
> = ["EstimatedCreditScore"];
export const convictionIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["ConvictionIndicator"]
> = ["ConvictionIndicator"];

export const outstandingLawsuitsIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["OutstandingLawsuitsIndicator"]
> = ["OutstandingLawsuitsIndicator"];

export const outstandingJudgmentsIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["OutstandingJudgmentsIndicator"]
> = ["OutstandingJudgmentsIndicator"];

export const bankruptcyIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["BankruptcyIndicator"]
> = ["BankruptcyIndicator"];

export const foreclosureIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["ForeclosureIndicator"]
> = ["ForeclosureIndicator"];

export const mortgageDelinquentIndicatorFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["MortgageDelinquentIndicator"]
> = ["MortgageDelinquentIndicator"];
export const statedCashOnHandAmountFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["StatedCashOnHandAmount"]
> = ["StatedCashOnHandAmount"];
export const locExpirationDateAggregationFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["aggregations", "LOCExpirationDate"]
> = ["aggregations", "LOCExpirationDate"];

export const taxpayerIdentifierTypeFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["TaxpayerIdentifierType"]
> = ["TaxpayerIdentifierType"];

export const taxpayerIdentifierValueFieldPath: ObjectKeyValidation<
  IndividualParty,
  ["TaxpayerIdentifierValue"]
> = ["TaxpayerIdentifierValue"];
