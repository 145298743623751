import {
  Asset,
  CreditScore,
  Deal,
  DealPartyRelation,
  DealProperty,
  ElphiEntityType,
  Party,
  PartyRelation,
  Property,
  RolodexServiceProvider,
  Statement,
  Task
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { WritableDraft } from "immer/dist/internal";
import { merge, toLower } from "lodash";
import { EMPTY, NOT_AVAILABLE } from "../../constants/common";
import {
  assetToString,
  creditScoreToString,
  serviceProviderToString,
  statementToString
} from "../asset/utils/printUtils";
import {
  getDealPartyNameText,
  getDealPropertyNameText,
  getPartyNamesText,
  splitRelationId
} from "../deal/utils/table.utils";
import { orderStatusHandlerFactory } from "./orderStatus/handlers";

import { GetOrderStatusResponse } from "./orderStatus/types";
import {
  getDealIdentifier,
  getPartyName,
  getPropertyAddress
} from "./taskPrint.utils";

export type TaskRowData = {
  task: Task | undefined;
  property: undefined | WritableDraft<Property>;
  party: undefined | WritableDraft<Party>;
  deal: "" | 0 | WritableDraft<Deal> | undefined;
  dealParty: undefined | WritableDraft<DealPartyRelation>;
  partyRelation: undefined | WritableDraft<PartyRelation>;
  dealProperty: undefined | WritableDraft<DealProperty>;
  parentParty: undefined | WritableDraft<Party>;
  childParty: undefined | WritableDraft<Party>;
  asset: undefined | WritableDraft<Asset>;
  statement: undefined | WritableDraft<Statement>;
  assetParties: (undefined | WritableDraft<Party>)[];
  creditScore: undefined | WritableDraft<CreditScore>;
  creditScoreParty: undefined | WritableDraft<Party>;
  followupTasks: undefined | WritableDraft<Task>[];
  serviceProvider: undefined | WritableDraft<RolodexServiceProvider>;
};

const buildTasksIdsChain = (ids: string[]): string[] => {
  const relationsIds: string[] = [];
  for (let i = ids.length - 2; i >= 0; i--) {
    relationsIds.push(ids.slice(i).join("_"));
  }
  return relationsIds;
};

export const getTaskRowData = (
  task: Task | undefined,
  props: any
): TaskRowData => {
  const {
    dealPropertyState,
    dealPartyState,
    propertyState,
    partyRelationState,
    partyState,
    partyAssetState,
    selectedDeal,
    assetState,
    statementState,
    creditScoreState,
    taskState,
    serviceProviderState
  } = props;
  const relationshipType = task?.entityType;
  const relationshipId = task?.entityId;

  const res: TaskRowData = {
    task: task,
    deal: selectedDeal,
    property: undefined,
    party: undefined,
    parentParty: undefined,
    childParty: undefined,
    dealParty: undefined,
    dealProperty: undefined,
    partyRelation: undefined,
    asset: undefined,
    statement: undefined,
    creditScore: undefined,
    creditScoreParty: undefined,
    serviceProvider: undefined,
    assetParties: [],
    followupTasks: []
  };

  const getOriginalTaskRowData = (
    originalTask: Task,
    relationsIds: string[]
  ): TaskRowData => {
    const originalTaskRowData = getTaskRowData(originalTask, props);
    relationsIds.forEach((x) =>
      originalTaskRowData.followupTasks!.push(taskState.entities[x])
    );
    return originalTaskRowData;
  };

  if (!!relationshipId) {
    if (relationshipType === ElphiEntityType.task) {
      const rootEntityId = task.rootTaskEntityId!;
      const regex = new RegExp(`_${rootEntityId}$`);
      const tasksPart = relationshipId.replace(regex, EMPTY);

      const ids = tasksPart.split("_");
      ids.push(rootEntityId);
      const tasksIdsChain = buildTasksIdsChain(ids);
      const [originalTaskId] = tasksIdsChain;
      const originalTask = taskState.entities[originalTaskId];
      const originalTaskRowData = getOriginalTaskRowData(
        originalTask,
        tasksIdsChain
      );

      return merge(res, originalTaskRowData);
    } else if (relationshipType === ElphiEntityType.property) {
      res.property = propertyState.entities[relationshipId];
    } else if (relationshipType === ElphiEntityType.party) {
      res.party = partyState.entities[relationshipId];
    } else if (relationshipType === ElphiEntityType.dealParty) {
      const { suffixId: partyId } = splitRelationId(relationshipId);
      res.party = partyState.entities[partyId];
      res.dealParty = dealPartyState.entities[relationshipId];
    } else if (relationshipType === ElphiEntityType.dealProperty) {
      const { suffixId: propertyId } = splitRelationId(relationshipId);
      res.property = propertyState.entities[propertyId];
      res.dealProperty = dealPropertyState.entities[relationshipId];
    } else if (relationshipType === ElphiEntityType.partyRelation) {
      const { prefixId: parentId, suffixId: childId } =
        splitRelationId(relationshipId);
      res.parentParty = partyState.entities[parentId];
      res.childParty = partyState.entities[childId];
      res.partyRelation = partyRelationState.entities[relationshipId];
    } else if (relationshipType === ElphiEntityType.asset) {
      res.asset = assetState.entities[relationshipId];
      const partyIds = Object.values(partyAssetState.entities)
        .filter((p: any) => p?.assetId === (res.asset?.id || ""))
        .map((p: any) => p?.partyId || "");
      res.assetParties = partyIds.map((id) => partyState.entities[id]);
    } else if (relationshipType === ElphiEntityType.statement) {
      res.statement = statementState.entities[relationshipId];
      if (res.statement?.assetId) {
        res.asset = assetState.entities[res.statement?.assetId];
      }
      if (res.asset) {
        const partyIds = Object.values(partyAssetState.entities)
          .filter((p: any) => p?.assetId === (res.asset?.id || ""))
          .map((p: any) => p?.partyId || "");
        res.assetParties = partyIds.map((id) => partyState.entities[id]);
      }
    } else if (relationshipType === ElphiEntityType.creditScore) {
      res.creditScore = creditScoreState.entities[relationshipId];
      res.creditScoreParty = !!res.creditScore?.partyId
        ? partyState.entities[res.creditScore?.partyId]
        : undefined;
    }
    if (relationshipType === ElphiEntityType.serviceProvider) {
      const serviceProvider = serviceProviderState?.entities?.[relationshipId];
      res.serviceProvider = serviceProvider || undefined;
    }
  }
  return res;
};
export const taskRowDataToText = (p: { task?: Task; props: any }): string => {
  if (!!p.task) {
    const rowData = getTaskRowData(p.task, p.props);
    const entityText = getTaskEntityText(rowData);
    return entityText || NOT_AVAILABLE;
  } else {
    return NOT_AVAILABLE;
  }
};

export const getOrderStatusData = (
  rowData: TaskRowData
): GetOrderStatusResponse | undefined => {
  const { task } = rowData;
  if (!task) return undefined;

  const { type } = task;
  const handler = orderStatusHandlerFactory(type);
  return handler ? handler(rowData) : undefined;
};

export const getOrderStatusOptions = ({
  task,
  props
}: {
  props: any;
  task?: Task;
}): string[] | string => {
  if (!task) {
    return NOT_AVAILABLE;
  }
  const rowData = getTaskRowData(task, props);
  const orderStatusData = getOrderStatusData(rowData);
  const orderStatusValues = orderStatusData
    ? Object.values(orderStatusData).filter(removeEmpty)
    : [];
  return orderStatusValues.map(toLower);
};

export const getTaskEntityText = (
  rowData: TaskRowData & { shouldSanitize?: boolean },
  delemiter: string = ","
): string | null => {
  const entityType = rowData.task?.entityType;
  const { shouldSanitize } = rowData;
  if (!entityType) {
    return null;
  }
  if (entityType === ElphiEntityType.task) {
    if (!rowData.followupTasks) {
      return EMPTY;
    }
    return getFollowupTaskNames(rowData).join(">");
  }
  if (entityType === ElphiEntityType.property) {
    return getPropertyAddress(rowData.property);
  }
  if (entityType === ElphiEntityType.party) {
    return getPartyName(rowData.party);
  }
  if (entityType === ElphiEntityType.deal) {
    const dealIdentifier = getDealIdentifier(rowData?.deal);
    return shouldSanitize ? dealIdentifier : `Deal: ${dealIdentifier}`;
  }
  if (entityType === ElphiEntityType.dealParty && !!rowData?.dealParty) {
    if (shouldSanitize) {
      const deal = getDealIdentifier(rowData?.deal);
      const party = getPartyName(rowData.party);
      return `${deal} ${party}`;
    }

    const dealPartyNameText = getDealPartyNameText(rowData.deal, rowData.party);
    return `${dealPartyNameText.deal} - ${dealPartyNameText.party}`;
  }
  if (entityType === ElphiEntityType.partyRelation && rowData?.partyRelation) {
    if (shouldSanitize) {
      const parentParty = getPartyName(rowData.parentParty);
      const childParty = getPartyName(rowData.childParty);

      return `${parentParty} ${childParty}`;
    }
    return getPartyNamesText([rowData.parentParty, rowData.childParty]).join(
      delemiter
    );
  }
  if (entityType === ElphiEntityType.dealProperty && !!rowData?.dealProperty) {
    if (shouldSanitize) {
      const deal = getDealIdentifier(rowData?.deal);
      const property = getPropertyAddress(rowData.property);
      return `${deal} ${property}`;
    }
    const dealPropertyNameText = getDealPropertyNameText(
      rowData.deal,
      rowData.property
    );
    return `${dealPropertyNameText.deal} - ${dealPropertyNameText.property}`;
  }
  if (entityType === ElphiEntityType.statement) {
    return statementToString({
      statement: rowData?.statement || ({} as Statement),
      asset: rowData?.asset || ({} as Asset),
      parties: rowData?.assetParties
    });
  }
  if (entityType === ElphiEntityType.asset) {
    return assetToString({
      asset: rowData?.asset || ({} as Asset),
      parties: rowData?.assetParties
    });
  }
  if (entityType === ElphiEntityType.creditScore) {
    return creditScoreToString({
      creditScore: rowData?.creditScore ?? ({} as CreditScore),
      party: rowData?.creditScoreParty || ({} as Party)
    });
  }
  if (entityType === ElphiEntityType.serviceProvider) {
    return serviceProviderToString(
      rowData?.serviceProvider as RolodexServiceProvider
    );
  }
  return null;
};

export const getFollowupTaskNames = (rowData: TaskRowData): string[] => {
  if (
    rowData.task?.entityType !== ElphiEntityType.task ||
    !rowData.followupTasks
  ) {
    return [NOT_AVAILABLE];
  }

  const firstTask = rowData.followupTasks.find(
    (t) => t.entityType !== ElphiEntityType.task
  );
  const firstTaskName =
    getTaskEntityText({ ...rowData, task: firstTask }) || NOT_AVAILABLE;
  return [firstTaskName, ...rowData.followupTasks.map((x) => x.name)];
};
