import {
  createSelector,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";

import { Deal, DealMilestoneType, LenderIdentifierType } from "@elphi/types";
import lodash from "lodash";
import { RootState } from "../../store";
import sliceBuilder from "../builders/slice.builder";
import { documentPackageOrderApi } from "../document-package-order/documentPackageOrder.service";
import { losUserApi } from "../los-user/losUser.service";
import { partyApi } from "../party/party.service";
import { propertyApi } from "../property/property.service";
import { SearchCursorState } from "../utils/search.utils";
import { dealEntityAdapter } from "./deal.adapter";
import { dealApi } from "./deal.service";
import { uniqCursorId } from "./utils";
export type DealTableFilterState = {
  lenderIdentifier?: LenderIdentifierType[];
  lenderIdentifierOp: "in" | "not-in";
  dealMilestone?: DealMilestoneType[];
  dealMilestoneOp: "in" | "not-in";
  selectedUserId?: string;
  estimatedClosingDateRange?: { from?: string; to?: string };
};

export type DealPageViews = "calendar" | "list";

export type DealSliceStateFields = {
  selectedId?: EntityId;
  selectedDealPageView: DealPageViews;
  searchCursor: SearchCursorState;
  searchResultsIds: EntityId[];
  paginationResultsIds: EntityId[];
  dealTable: {
    selectedDealId?: string;
    cursor: {
      currentId: string;
      //focused: "all" | "userFilter" | "dealFilter" | "dealUserFilter";
      /** agnostic field contains a map of all used cursors with uniq id key, and their cursor as the value
       * the format used is dealId_userId_lenders_milesones
       */
      hasMore: {
        [uniqId: string]: boolean;
      };
      agnostic: {
        [uniqId: string]: string;
      };
    };
    filter: DealTableFilterState;
  };
};
export type DealSliceState = EntityState<Deal> & DealSliceStateFields;

export enum DealTableCursorType {
  Agnostic
}
export const initialState: DealSliceStateFields = {
  selectedId: undefined,
  selectedDealPageView: "list",
  searchResultsIds: [],
  paginationResultsIds: [],
  searchCursor: {
    query: {}
  },
  dealTable: {
    cursor: {
      currentId: "none",
      hasMore: {},
      agnostic: {}
    },
    filter: {
      dealMilestone: [],
      lenderIdentifier: [],
      dealMilestoneOp: "in",
      lenderIdentifierOp: "in",
      estimatedClosingDateRange: {}
    }
  }
};
export const dealSlice = createSlice({
  name: "deal",
  initialState: dealEntityAdapter.getInitialState(initialState),
  reducers: {
    update: dealEntityAdapter.updateOne,
    remove: dealEntityAdapter.removeOne,
    add: dealEntityAdapter.addOne,
    upsert: dealEntityAdapter.upsertOne,
    upsertMany: dealEntityAdapter.upsertMany,
    removeMany: dealEntityAdapter.removeMany,
    updateMany: dealEntityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setSelectedDealPageView: (
      state,
      action: PayloadAction<{ pageView: DealPageViews }>
    ) => {
      state.selectedDealPageView = action.payload.pageView;
    },
    setDealTableFilter: (
      state,
      action: PayloadAction<DealTableFilterState>
    ) => {
      state.dealTable.filter = { ...state.dealTable.filter, ...action.payload };
    },
    dealTableSelectedDealId: (state, action: PayloadAction<{ id: string }>) => {
      state.dealTable = {
        ...(state.dealTable ?? {}),
        selectedDealId: action.payload.id
      };
    },
    setDealTableCurrentCursorId: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.dealTable.cursor.currentId = action.payload.id;
    },
    setDealTableCurrentCursorIdHasMore: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.dealTable.cursor.hasMore[state.dealTable.cursor.currentId] =
        action.payload;
    },

    setDealTableCursor: (
      state,
      action: PayloadAction<{
        cursorType: DealTableCursorType;
        cursor: string;
        dealId?: string;
        userId?: string;
        lender?: LenderIdentifierType[];
        milestone?: DealMilestoneType[];
        milestoneOp?: "in" | "not-in";
        estimatedClosingDateRange?: { from?: string; to?: string };
      }>
    ) => {
      /**
       * Check WHY its failing to get the correct uniq cursor
       */

      const {
        dealId,
        userId,
        lender,
        milestone,
        milestoneOp,
        estimatedClosingDateRange
      } = action.payload;
      const filtersUniqCursorId = uniqCursorId({
        dealId,
        userId,
        lender,
        milestone,
        milestoneOp,
        estimatedClosingDateRange
      });
      const defaultCursorState = {
        currentId: filtersUniqCursorId,
        hasMore: {},
        agnostic: {}
      };
      if (!state?.dealTable) {
        state.dealTable.cursor = { ...defaultCursorState };
      }

      state.dealTable.cursor = {
        ...state.dealTable.cursor,
        agnostic: {
          ...state.dealTable.cursor.agnostic,
          [filtersUniqCursorId || "none"]: action.payload.cursor
        }
      };
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(dealApi)(builder);
    builder.addMatcher(
      partyApi.endpoints.partyDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.deals.ids);
        state.entities = { ...state.entities, ...payload.deals.entities };
      }
    );
    builder.addMatcher(
      losUserApi.endpoints.userDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.deals.ids);
        state.entities = { ...state.entities, ...payload.deals.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.getDealBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.deals.ids);
        state.entities = { ...state.entities, ...payload.deals.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.searchResultsIds = lodash.union(
          state.searchResultsIds,
          payload.results.ids
        );
        state.ids = lodash.union(state.ids, payload.results.ids);
        state.entities = {
          ...state.entities,
          ...payload.results.entities
        };
        state.searchCursor.query[payload.query] = {
          hasMore: payload.hasMore,
          nextCursor: payload.nextCursor
        };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.propertyDeals.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.deals.ids);
        state.entities = { ...state.entities, ...payload.deals.entities };
      }
    );
    builder.addMatcher(
      partyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.deal.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.deal, "id")
        };
      }
    );
    builder.addMatcher(
      propertyApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(
          state.ids,
          payload.page.deal.map((p) => p.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.deal, "id")
        };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.paginateV2.matchFulfilled,
      (state, { payload }) => {
        state.paginationResultsIds = lodash.union(
          state.paginationResultsIds,
          payload.page.deal.map((v) => v.id)
        );
        state.ids = lodash.union(
          state.ids,
          payload.page.deal.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.page.deal, "id")
        };
      }
    );
    builder.addMatcher(
      documentPackageOrderApi.endpoints.getEntitiesData.matchFulfilled,
      (state, { payload }) => {
        state.ids = lodash.union(state.ids, payload.deals.ids);
        state.entities = { ...state.entities, ...payload.deals.entities };
      }
    );
    builder.addMatcher(
      dealApi.endpoints.getAdditionalDataForDeals.matchFulfilled,
      (state, { payload }) => {
        state.paginationResultsIds = lodash.union(
          state.paginationResultsIds,
          payload.deals.map((v) => v.id)
        );
        state.ids = lodash.union(
          state.ids,
          payload.deals.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...lodash.keyBy(payload.deals, "id")
        };
      }
    );
  }
});

export const dealPropertiesSelector = (state: RootState, dealId: EntityId) =>
  state.dealPropertyRelation.ids
    .filter(
      (id) =>
        state.dealPropertyRelation.entities?.[id]?.dealId === dealId &&
        !!state.dealPropertyRelation.entities?.[id]?.propertyId
    )
    .map(
      (dealPropertyRelId) =>
        state.property.entities?.[
          state.dealPropertyRelation.entities[dealPropertyRelId]?.propertyId!
        ]
    );

const selectDealId = (_: RootState, dealId: string) => dealId;
const selectProperties = (state: RootState) => state.property;
const selectDealPropertiesRelation = (state: RootState) =>
  state.dealPropertyRelation;

export const selectPropertiesByIds = (state: RootState, ids: string[]) =>
  ids.map((id) => state.property.entities[id]);

export const selectDealPropertiesRelationByDealId = (
  state: RootState,
  dealId: string
) =>
  state.dealPropertyRelation.ids.filter(
    (id) =>
      state.dealPropertyRelation.entities?.[id]?.dealId === dealId &&
      !!state.dealPropertyRelation.entities?.[id]?.propertyId
  );

export const dealPropertiesSelectorV2 = createSelector(
  [selectDealPropertiesRelation, selectProperties, selectDealId],
  (dealPropertyRelation, property, dealId) => {
    return dealPropertyRelation.ids
      .filter(
        (id) =>
          dealPropertyRelation.entities?.[id]?.dealId === dealId &&
          !!dealPropertyRelation.entities?.[id]?.propertyId
      )
      .map(
        (dealPropertyRelId) =>
          property.entities?.[
            dealPropertyRelation.entities[dealPropertyRelId]?.propertyId!
          ]
      );
  }
);

const selectDealPartiesRelation = (state: RootState) => state.dealPartyRelation;

export const dealPartiesRelationSelector = createSelector(
  [selectDealPartiesRelation, selectDealId],
  (dealPartyRelations, dealId) => {
    return dealPartyRelations.ids.filter(
      (id: string) =>
        dealPartyRelations.entities?.[id]?.dealId === dealId &&
        !!dealPartyRelations.entities?.[id]?.partyId
    );
  }
);
