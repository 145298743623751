import { BaseEntity } from "../../db";
import { ServiceProviderEntityType } from "./rolodex.enums";

type Status = "active" | "disabled";

export type RolodexEntityFields = {
  [key: string]: { fieldPath: string; isRequired: boolean };
};

export enum RolodexConfigurationFieldType {
  CompanyFields = "companyFields",
  BranchFields = "branchFields",
  RepresentativeFields = "representativeFields"
}

export type RolodexFields = {
  [P in RolodexConfigurationFieldType]: RolodexEntityFields;
};

export type RolodexConfiguration = BaseEntity<{
  name: string;
  entityType: ServiceProviderEntityType;
  fields: Partial<RolodexFields>;
  status: Status;
}>;
