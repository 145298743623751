import {
  LabelValue,
  RolodexServiceProvider,
  ServiceProviderType
} from "@elphi/types";
import { FieldsWeight } from "../../../../../utils/ranked.utils";

export const dataRank: FieldsWeight<RolodexServiceProvider> = {
  name: 50,
  firstName: 50,
  lastName: 40,
  middleName: 30
};

export const addProviderOption = {
  [ServiceProviderType.Company]: {
    label: "+ Add a new company",
    value: "addNewCompany"
  },
  [ServiceProviderType.Branch]: {
    label: "+ Add a new branch",
    value: "addNewBranch"
  },
  [ServiceProviderType.Representative]: {
    label: "+ Add a new representative",
    value: "addNewRep"
  }
};

const isPersonalRolodex = (r: {
  provider: RolodexServiceProvider;
  dealPartyIndividuals: Set<string>;
}) =>
  !!r.provider.partyIds.find((partyId) => r.dealPartyIndividuals.has(partyId));

export const buildSearchOption = (r: {
  provider: RolodexServiceProvider;
  dealPartyIndividuals: Set<string>;
}): LabelValue & { isPersonalRolodex: boolean } => {
  const { provider } = r;
  const value = provider.id;
  if (provider.type === ServiceProviderType.Representative) {
    if (provider.middleName) {
      return {
        label: `${provider.firstName} ${provider.middleName} ${provider.lastName}`,
        value,
        isPersonalRolodex: isPersonalRolodex(r)
      };
    }
    return {
      label: `${provider.firstName} ${provider.lastName}`,
      value,
      isPersonalRolodex: isPersonalRolodex(r)
    };
  }
  return {
    label: provider.name,
    value,
    isPersonalRolodex: isPersonalRolodex(r)
  };
};
