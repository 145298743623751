import {
  Box,
  Button,
  Flex,
  FlexboxProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TextProps,
  useDisclosure
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import elphiTheme from "../../assets/themes/elphi.theme.default";

import { isFunction } from "lodash";

export type ModalSubmit = {
  onConfirm?: Function;
  confirmTitle?: string;
  closeTitle?: string;
  isDisabled?: boolean;
  showClose?: boolean;
  isLoading?: boolean;
};

export type CustomStyles = {
  footer?: FlexboxProps | TextProps;
};

export type ModalContainerProps = {
  isShow?: boolean;
  onCloseModal?: Function;
  onCloseComplete?: Function;
  maxWidth?: number;
  header?: React.ReactNode | string;
  body?: React.ReactNode | string;
  footer?: React.ReactNode | string;
  submit?: ModalSubmit;
  children?: React.ReactNode;
  customStyles?: CustomStyles;
};

export const ModalContainer = ({
  isShow = false,
  onCloseModal,
  onCloseComplete,
  maxWidth = 600,
  body,
  children,
  header,
  footer,
  submit,
  customStyles
}: ModalContainerProps) => {
  const { showClose = true } = submit || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [show, setShow] = useState<boolean>(!!children ? isOpen : isShow);

  useEffect(() => {
    setShow(!!children ? isOpen : isShow);
  }, [isOpen, isShow]);

  const onExit = () => {
    isFunction(onCloseModal) && onCloseModal();
    !!children && onClose();
  };

  const onExitComplete = () => {
    isFunction(onCloseComplete) && onCloseComplete();
  };

  return (
    <>
      {!!children && <Box onClick={onOpen}>{children}</Box>}
      <Modal isOpen={show} onClose={onExit} onCloseComplete={onExitComplete}>
        <ModalOverlay />
        <ModalContent minWidth={`${maxWidth}px`}>
          <ModalHeader>{header}</ModalHeader>
          <ModalCloseButton />
          {!!body && <ModalBody>{body}</ModalBody>}
          {(footer || !!submit) && (
            <ModalFooter>
              <Flex flexDir={"column"} {...customStyles?.footer}>
                {!!submit && (
                  <Flex
                    w={"100%"}
                    flexDir={"row"}
                    justifyContent={"end"}
                    mr={"15px"}
                  >
                    {isFunction(submit?.onConfirm) && (
                      <Button
                        {...elphiTheme.components.light.button.primary}
                        mr={"15px"}
                        disabled={submit?.isDisabled}
                        isLoading={submit?.isLoading}
                        onClick={() => {
                          submit?.onConfirm!();
                          onExit();
                        }}
                      >
                        {submit?.confirmTitle || "Confirm"}
                      </Button>
                    )}
                    {showClose && (
                      <Button
                        {...elphiTheme.components.light.button.secondary}
                        onClick={() => {
                          onExit();
                        }}
                      >
                        {submit?.closeTitle || "Cancel"}
                      </Button>
                    )}
                  </Flex>
                )}
                {!!footer && <Box>{footer}</Box>}
              </Flex>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalContainer;
