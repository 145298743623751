import { ElphiEntityType, getCreditReportTemplate } from "@elphi/types";
import { flatten } from "flat";
import { startsWith, transform } from "lodash";
import { accountAssetFieldSpecs } from "../../../form-builder/field-specs/asset/asset.fields";
import { creditReportFieldSpecs } from "../../../form-builder/field-specs/credit-report/credit-report.fields";
import { dealPropertyFieldSpecs } from "../../../form-builder/field-specs/deal-property/deal-property.fields";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import { propertySpecFields } from "../../../form-builder/field-specs/property/property.fields";
import { statementFieldSpecs } from "../../../form-builder/field-specs/statement/statement.fields";
import { taskSpec } from "../../../form-builder/field-specs/task/task.fields";
import {
  assetFields,
  buildLabel,
  creditScoreFields,
  dealFields,
  dealPartyFieldSpecs,
  dealPartyFields,
  dealPropertyFields,
  hasDefaultFocus,
  partyFieldSpecs,
  partyFields,
  partyRelationFieldSpecs,
  partyRelationFields,
  propertyFields,
  serviceProviderFieldSpecs,
  serviceProviderFields,
  statementFields,
  taskFields
} from "../field-selection.utils";
import {
  FieldMappingDistinctOption,
  FieldSelectionBuilder
} from "./field-selection.builder.types";

type CreditReportNestedPropsType = Pick<
  ReturnType<typeof getCreditReportTemplate>,
  "equifax" | "experian" | "transunion"
>;

const creditReportFieldPathsToDistinct: Array<
  keyof CreditReportNestedPropsType
> = ["equifax", "experian", "transunion"];

const entitiesFieldPathsToDistinct = ["aggregations", "quote.aggregations"];

export const fieldSelection: FieldSelectionBuilder = {
  build: (
    entityType?: ElphiEntityType
  ): {
    label: string;
    value: string;
  }[] => {
    const mapping = entityType ? entityTypeMappings[entityType] : undefined;
    return mapping ? buildOptions(mapping) : [];
  }
};

const entityTypeMappings: Record<ElphiEntityType, FieldMappingDistinctOption> =
  {
    [ElphiEntityType.deal]: {
      fields: dealFields,
      fieldSpecs: dealFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.property]: {
      fields: propertyFields,
      fieldSpecs: propertySpecFields,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.party]: {
      fields: partyFields,
      fieldSpecs: partyFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.dealParty]: {
      fields: dealPartyFields,
      fieldSpecs: dealPartyFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.partyRelation]: {
      fields: partyRelationFields,
      fieldSpecs: partyRelationFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.dealProperty]: {
      fields: dealPropertyFields,
      fieldSpecs: dealPropertyFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.asset]: {
      fields: assetFields,
      fieldSpecs: accountAssetFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.statement]: {
      fields: statementFields,
      fieldSpecs: statementFieldSpecs,
      pathsToDistinct: entitiesFieldPathsToDistinct
    },
    [ElphiEntityType.creditScore]: {
      fields: creditScoreFields,
      fieldSpecs: creditReportFieldSpecs,
      pathsToDistinct: creditReportFieldPathsToDistinct
    },
    [ElphiEntityType.task]: {
      fields: taskFields,
      fieldSpecs: taskSpec,
      pathsToDistinct: []
    },
    [ElphiEntityType.serviceProvider]: {
      fields: serviceProviderFields,
      fieldSpecs: serviceProviderFieldSpecs,
      pathsToDistinct: []
    }
  };

export const extractDistinctedFieldsFromPath = (
  obj: any,
  fieldPaths: string[]
) => {
  return transform(
    obj,
    (result, value, key: string) => {
      for (const path of fieldPaths) {
        if (startsWith(key, path)) {
          const hasFocus = hasDefaultFocus(key);
          if (hasFocus) {
            result[key] = "";
          }
          return;
        }
      }
      result[key] = value;
    },
    {}
  );
};

const buildOptions = (option: FieldMappingDistinctOption) => {
  const distinctedFields = extractDistinctedFieldsFromPath(
    flatten(option.fields, { safe: true }),
    option.pathsToDistinct
  );
  return Object.keys(distinctedFields).map((key) => {
    return {
      label: buildLabel({ object: option.fieldSpecs, key }),
      value: key
    };
  });
};
