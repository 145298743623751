export enum ElphiEntityType {
  party = "party",
  property = "property",
  deal = "deal",
  dealParty = "deal-party",
  partyRelation = "party-relation",
  dealProperty = "deal-property",
  asset = "asset",
  statement = "statement",
  creditScore = "credit-score",
  task = "task",
  serviceProvider = "service-provider"
}
