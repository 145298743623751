import { Flex, Text } from "@chakra-ui/react";
import { DealCalendarType, formatNumberWithCommas } from "@elphi/types";
import {
  DATES_FORMATS,
  MAX_SHOW_DEAL_CALENDARS,
  elphiDate
} from "@elphi/utils";
import { useEffect, useState } from "react";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";

export type DealCalendarDayProps = { currentDay: Date };

export const DealCalendarFooterDay = ({ currentDay }: DealCalendarDayProps) => {
  const { dealCalendarState, selectedDateType } = useDealCalendarHooks();
  const currentDate = elphiDate(currentDay).format(DATES_FORMATS.YYYY_MM_DD);
  const [currentDayDealsLength, setCurrentDayDealsLength] = useState(
    dealCalendarState.dates?.[selectedDateType]?.[currentDate]
      ?.totalDealsCalendar
  );
  const [totalRequestedLoanAmount, setTotalRequestedLoanAmount] = useState(
    dealCalendarState.dates?.[selectedDateType]?.[currentDate]
      ?.totalRequestedLoanAmount || 0
  );

  const getCurrentLength = (
    deals: DealCalendarType[],
    totalDealsCalendar: number
  ): number => {
    if (totalDealsCalendar && deals.length) {
      return totalDealsCalendar > MAX_SHOW_DEAL_CALENDARS
        ? totalDealsCalendar - MAX_SHOW_DEAL_CALENDARS
        : totalDealsCalendar !== deals.length
        ? Math.abs(totalDealsCalendar - deals.length)
        : 0;
    }
    return 0;
  };

  useEffect(() => {
    const { deals, totalDealsCalendar, totalRequestedLoanAmount } =
      dealCalendarState.dates?.[selectedDateType]?.[currentDate] || {};
    const moreLength = getCurrentLength(deals, totalDealsCalendar);
    setCurrentDayDealsLength(moreLength);
    setTotalRequestedLoanAmount(totalRequestedLoanAmount || 0);
  }, [dealCalendarState.dates?.[selectedDateType]?.[currentDate]]);

  return (
    <Flex
      flexDir={"row"}
      justifyContent={"space-between"}
      w={"100%"}
      h={"100%"}
      alignItems={"center"}
      minH={"20px"}
    >
      <Flex>
        {!!currentDayDealsLength && (
          <Text fontWeight={700} fontSize={"12px"}>
            {currentDayDealsLength} more
          </Text>
        )}
      </Flex>
      <Flex>
        {!!totalRequestedLoanAmount && (
          <Text fontWeight={700} fontSize={"12px"}>
            ${formatNumberWithCommas(totalRequestedLoanAmount.toString())}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};
