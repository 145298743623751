import {
  LabelValue,
  PartyType,
  ServiceProviderEntityType,
  ServiceProviderStatusType
} from "@elphi/types";
import { NONE } from "../../../../../../constants/common";
import {
  HandHouseBlue100Icon,
  HouseOrange100Icon,
  HumanCyan100Icon,
  HumanPurple100Icon,
  ShieldSucceededPurpleIcon
} from "../../../../../icons";
import { createOptionsFromEnum } from "../../../../../utils/formUtils";

export const SERVICE_PROVIDER_STATUS_BG_COLOR = {
  [ServiceProviderStatusType.Active]: "green.100",
  [ServiceProviderStatusType.Unresponsive]: "teal.100",
  [ServiceProviderStatusType.Closed]: "orange.100",
  [ServiceProviderStatusType.Prohibited]: "red.100",
  default: "gray.100"
} as const;

export const SERVICE_PROVIDER_STATUS_COLOR_TEXT = {
  [ServiceProviderStatusType.Active]: "green.800",
  [ServiceProviderStatusType.Unresponsive]: "teal.800",
  [ServiceProviderStatusType.Closed]: "yellow.800",
  [ServiceProviderStatusType.Prohibited]: "red.800",
  default: "gray.800"
} as const;

export const entityTypeIconMap = {
  [ServiceProviderEntityType.Deal]: () => <HandHouseBlue100Icon w={6} h={6} />,
  [ServiceProviderEntityType.Party]: (e?: PartyType) => {
    return e === PartyType.Entity ? (
      <HumanPurple100Icon w={6} h={6} />
    ) : (
      <HumanCyan100Icon w={6} h={6} />
    );
  },
  [ServiceProviderEntityType.Property]: () => (
    <HouseOrange100Icon w={6} h={6} />
  ),
  [ServiceProviderEntityType.InsurancePolicy]: () => (
    <ShieldSucceededPurpleIcon w={6} h={6} />
  )
};

export const entityTypeTextMap = {
  [ServiceProviderEntityType.Deal]: "Deal",
  [ServiceProviderEntityType.Party]: "Party",
  [ServiceProviderEntityType.Property]: "Property",
  [ServiceProviderEntityType.InsurancePolicy]: "Insurance policy"
};

export const statusOptions = createOptionsFromEnum(
  ServiceProviderStatusType
).map((x): LabelValue => x);

export const COLLAPSE_HEIGHT = {
  No_Entity: 132,
  One_Entity: 177,
  More_Then_One_Entity: 190
} as const;

export enum GroupByOptions {
  ServiceDomain = "serviceDomain",
  EntityType = "entityType"
}

export const groupByOptionsMap = {
  [GroupByOptions.ServiceDomain]: "Service Domain",
  [GroupByOptions.EntityType]: "Entity Type",
  [NONE]: NONE
} as const;
