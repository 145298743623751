import { RolodexBranchRepresentativeRelation } from "@elphi/types";
import { EntityState, createSlice } from "@reduxjs/toolkit";
import { union } from "lodash";
import sliceBuilder from "../../builders/slice.builder";
import { branchRepRelationAdapter as entityAdapter } from "./branchRepRelation.adapter";
import { branchRepRelationApi } from "./branchRepRelation.service";
export type BranchRepRelationSliceState =
  EntityState<RolodexBranchRepresentativeRelation>;

const initialState: BranchRepRelationSliceState = {
  ids: [],
  entities: {}
};

export const branchRepRelationSlice = createSlice({
  name: "branchRepRelation",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(branchRepRelationApi)(builder);
    builder.addMatcher(
      branchRepRelationApi.endpoints.getDealBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
  }
});
