import { BaseEntity, FirestoreTimestampFieldUnionType } from "../../db";
import { DealMilestoneType, LenderIdentifierType } from "../deal.enums.types";
import { Deal, DealReadonlyField } from "../deal.types";
import { Property } from "../property.types";

export type DealCalendar = BaseEntity<DealCalendarType>;

export type DealCalendarType = Partial<DealCalendarFields>;

export type DealCalendarDateType = {
  deals: DealCalendarType[];
  totalDealsCalendar: number;
  totalRequestedLoanAmount?: number;
};

type DealCalendarFields = DealCalendarDealFields &
  DealCalendarPropertyFields &
  DealReadonlyField &
  DealCalendarCustomFields;

type DealCalendarCustomFields = Partial<{
  TotalCostAmount: string;
  EstimatedClosingDate: string;
  previousEstimatedClosingDate: string;
  milestone: DealMilestoneType;
  LenderIdentifier: LenderIdentifierType;
  assignedUsers: string[];
  modifiedAt: FirestoreTimestampFieldUnionType;
  RequestedLoanAmount: string;
  primaryBorrowers: DealCalendarPartyById;
  primarySponsors: DealCalendarPartyById;
  secondaryBorrowers: DealCalendarPartyById;
  secondarySponsors: DealCalendarPartyById;
  taskLength: number;
}>;

type DealCalendarDealFields = Pick<
  Deal,
  "id" | "LoanIdentifier" | "LoanName" | "createdAt"
>;

type DealCalendarPropertyFields = Pick<Property, "Address">;

export type DealCalendarParty = {
  FullName?: string;
  FirstName?: string;
  LastName?: string;
  MiddleName?: string;
  partyId: string;
  relationRoleType: string[];
};

export type DealCalendarPartyById = {
  [id: string]: DealCalendarParty;
};

export const DocumentDateTypesToDealIds = "dateTypesToDealIds";
