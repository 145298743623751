import { DealCalendarDateType } from "@elphi/types";
import { createApi } from "@reduxjs/toolkit/query/react";
import { isEmpty } from "lodash";
import { AppConfig } from "../../../config/appConfig";
import { DateType } from "../../../shared/types";
import { removeNulls } from "../../../utils/filter.utils";
import { RootState } from "../../store";
import serviceBuilder from "../builders/api.builder";
import { DealTableFilterState } from "../deal/deal.slice";
import { dealCalendarEntityAdapter } from "./dealCalendar.adapter";

export type DealCalendarDateRangeRequest = {
  from: string;
  to?: string;
  dateType: DateType;
  filters?: DealTableFilterState;
};

export type DealCalendarDateRangeResponse = {
  data?: {
    [date: string]: DealCalendarDateType;
  };
};

export const dealCalendarSelector =
  dealCalendarEntityAdapter.getSelectors<RootState>(
    (state) => state.dealCalendar
  );

export const baseDealCalendarApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "dealCalendarApi",
  tagTypes: ["DealCalendar", "Calendar", "DateRange"],
  baseQuery: serviceBuilder.baseQuery({
    route: "deal-calendar"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: dealCalendarEntityAdapter
  })
});

export const dealCalendarApi = baseDealCalendarApi.injectEndpoints({
  endpoints: (builder) => ({
    dateRange: builder.query<
      DealCalendarDateRangeResponse,
      DealCalendarDateRangeRequest
    >({
      query: (r) => {
        const {
          lenderIdentifier,
          lenderIdentifierOp,
          dealMilestone,
          dealMilestoneOp,
          selectedUserId,
          estimatedClosingDateRange
        } = r.filters || {};
        const urlFrom = r.from ? `from=${r.from}` : null;
        const urlTo = r.to ? `to=${r.to}` : r.from ? `to=${r.from}` : null;
        const urlDateType = r.dateType ? `date_type=${r.dateType}` : null;

        const urlUser = selectedUserId ? `user_id=${selectedUserId}` : null;

        const urlMilestoneOp = `milestone_op=${dealMilestoneOp}`;
        const urlLenderOp = `lender_op=${lenderIdentifierOp}`;
        const urlMilestone = dealMilestone?.length
          ? `milestone=${dealMilestone.join(";")}`
          : null;
        const urlLender = lenderIdentifier?.length
          ? `lender=${lenderIdentifier.join(";")}`
          : null;

        const estimatedClosingDateRangeValues = !isEmpty(
          estimatedClosingDateRange
        )
          ? [estimatedClosingDateRange?.from, estimatedClosingDateRange?.to]
          : null;

        const urlDateRange = estimatedClosingDateRangeValues?.length
          ? `estimated_closing_date_range=${estimatedClosingDateRangeValues.join(
              ";"
            )}`
          : null;

        const queryParams = [
          urlFrom,
          urlTo,
          urlDateType,
          urlUser,
          urlMilestone,
          urlLender,
          urlMilestoneOp,
          urlLenderOp,
          urlDateRange
        ]
          .filter(removeNulls)
          .join("&");

        const url = `/dateRange?${queryParams}`;
        return {
          url,
          method: "GET"
        };
      }
    })
  })
});
