import {
  DealPartyRelationType,
  LabelValue,
  RolodexServiceProvider,
  ServiceProviderEntityType,
  StatusCode
} from "@elphi/types";
import { PartialWithId } from "@elphi/types/services/service.types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { uniq } from "lodash";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import {
  getDealIdentifier,
  getPartyName,
  getPropertyAddress
} from "../components/task/taskPrint.utils";
import { useElphiToast } from "../components/toast/toast.hook";
import { EMPTY, NOT_AVAILABLE } from "../constants/common";
import { RootState } from "../redux/store";
import {
  RolodexServiceProviderRequest,
  serviceProviderApi,
  serviceProviderSlice
} from "../redux/v2/rolodex";
import { getListFromDictionary } from "../utils/batchUtils";
import useDealHooks from "./deal.hooks";
import { useDealPropertyRelationHooks } from "./dealPropertyRelation.hooks";
import { useDealPartyRelationHooks } from "./dealpartyrelation.hooks";
import { usePartyHooks } from "./party.hooks";
import { usePropertyHooks } from "./property.hooks";

const useEntitiesHooks = () => {
  const { selectedDeal } = useDealHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { propertyState } = usePropertyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();
  const { partyState } = usePartyHooks();

  const dealPropertyIds = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }

    const propertyIds = uniq(
      Object.values(dealPropertyRelationState.entities)
        .filter((x) => x?.dealId === selectedDeal.id)
        .map((x) => x?.propertyId)
        .filter(removeEmpty)
    );

    return propertyIds;
  }, [
    selectedDeal,
    dealPropertyRelationState.entities,
    propertyState.entities
  ]);

  const dealPartyIds = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }

    const partyIds = uniq(
      Object.values(dealPartyRelationState.entities)
        .filter((x) => x?.dealId === selectedDeal.id)
        .map((x) => x?.partyId)
        .filter(removeEmpty)
    );

    return partyIds;
  }, [selectedDeal, dealPartyRelationState.entities, partyState.entities]);

  const dealOptions = useMemo(() => {
    if (!selectedDeal) {
      return [];
    }
    return [
      {
        label: getDealIdentifier(selectedDeal),
        value: selectedDeal.id
      }
    ];
  }, [selectedDeal]);

  const partyOptions = useMemo(() => {
    return dealPartyIds.map((x) => {
      const p = partyState.entities[x];
      return {
        label: getPartyName(p),
        value: p?.id || EMPTY
      };
    });
  }, [dealPartyIds, partyState.entities]);

  const dealPropertyOptions = useMemo(() => {
    return dealPropertyIds.map((x) => {
      const p = propertyState.entities[x];
      return {
        label: getPropertyAddress(p),
        value: p?.id || NOT_AVAILABLE
      };
    });
  }, [dealPropertyIds, propertyState.entities]);

  const optionsMap: Record<ServiceProviderEntityType, LabelValue[]> = {
    [ServiceProviderEntityType.Deal]: dealOptions,
    [ServiceProviderEntityType.Property]: dealPropertyOptions,
    [ServiceProviderEntityType.Party]: partyOptions,
    [ServiceProviderEntityType.InsurancePolicy]: []
  };

  const dealPartyIndividualsSet = useMemo(() => {
    if (!selectedDeal) {
      return new Set<string>();
    }

    return new Set<string>(
      Object.values(dealPartyRelationState.entities)
        .filter(
          (x) =>
            x?.dealId === selectedDeal.id &&
            x.type === DealPartyRelationType.DealIndividual
        )
        .filter(removeEmpty)
        .map((x) => x.partyId)
    );
  }, [selectedDeal, dealPartyRelationState.entities]);

  const getDealEntitiesMap = (type: ServiceProviderEntityType) => {
    switch (type) {
      case ServiceProviderEntityType.Deal:
        if (!selectedDeal) return {};
        return {
          [selectedDeal.id]: {
            id: selectedDeal.id,
            text: selectedDeal.LoanIdentifier
          }
        };
      case ServiceProviderEntityType.Party:
        return dealPartyIds.reduce((p, v) => {
          const pr = partyState.entities[v];
          if (!pr) {
            return p;
          }
          p[v] = {
            id: pr.id,
            text: getPartyName(pr),
            type: pr?.PartyType
          };
          return p;
        }, {});
      case ServiceProviderEntityType.Property:
        return dealPropertyIds.reduce((p, v) => {
          const pr = propertyState.entities[v];
          if (!pr) {
            return p;
          }
          p[v] = {
            id: pr.id,
            text: getPropertyAddress(pr)
          };
          return p;
        }, {});
      case ServiceProviderEntityType.InsurancePolicy:
      default:
        return {};
    }
  };

  return {
    optionsMap,
    dealPartyIndividualsSet,
    getDealEntitiesMap,
    selectedDeal
  };
};

export const useServiceProviderHooks = () => {
  const dispatch = useDispatch();
  const {
    optionsMap,
    dealPartyIndividualsSet,
    getDealEntitiesMap,
    selectedDeal
  } = useEntitiesHooks();
  const { successToast, errorToast } = useElphiToast();
  const [upsertApi, upsertResponse] = serviceProviderApi.useUpsertMutation();
  const [removeDealRelationApi, removeDealRelationApiResponse] =
    serviceProviderApi.useRemoveDealRelationMutation();
  const [getByIdApi, getByIdApiResponse] = serviceProviderApi.useLazyGetQuery();
  const [getBatchApi, getBatchResponse] =
    serviceProviderApi.useLazyGetBatchQuery();
  const [updateBatchApi, updateBatchResponse] =
    serviceProviderApi.useBatchUpdateMutation();

  const createHandler = async (r: RolodexServiceProviderRequest) => {
    return await upsertApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Rolodex created",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create rolodex",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const updateHandler = async (r: RolodexServiceProviderRequest) => {
    return await upsertApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Rolodex updated",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update rolodex",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const removeDealRelationHandler = async (r: {
    companyId: string;
    branchId: string;
    representativeId: string;
    dealId: string;
  }) => {
    return await removeDealRelationApi(r)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Deal removed from service provider",
            description: JSON.stringify(r.data)
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to remove deal from service provider",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const getByIdHandler = async (r: { id: string }) => {
    await getByIdApi(r.id)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const getBatchHandler = async (ids: string[]) => {
    return await getBatchApi(ids)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get service provider batch",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const updateServiceProvidersHandler = async (map?: {
    [id: string]: PartialWithId<RolodexServiceProvider>;
  }) => {
    if (!map) {
      return;
    }
    const providers = getListFromDictionary(map);
    if (!providers.length) {
      return;
    }
    return await updateBatchApi({ providers } as {
      providers: ({
        id: string;
      } & Partial<RolodexServiceProvider>)[];
    })
      .then(responseHandler)
      .then((r) => {
        if (r?.status === StatusCode.OK) {
          successToast({
            title: "service providers update success",
            description: `${r?.data?.batch?.length} service providers updated`
          });
        }
        if (r?.status === StatusCode.BadRequest) {
          errorToast({
            title: "service providers update failed",
            description: `${r.data.description}`
          });
        }
        return r;
      });
  };

  const serviceProviderState = useSelector(
    (state: RootState) => state.serviceProvider
  );

  const setSelectedServiceProvider = (id: string) =>
    dispatch(serviceProviderSlice.actions.selectedId({ id }));

  const selectedServiceProvider = useSelector(
    (state: RootState) =>
      state.serviceProvider.selectedId &&
      state.serviceProvider.entities[state.serviceProvider.selectedId]
  );

  return {
    optionsMap,
    createHandler,
    updateHandler,
    upsertResponse,
    serviceProviderState,
    setSelectedServiceProvider,
    selectedServiceProvider,
    dealPartyIndividualsSet,
    getDealEntitiesMap,
    selectedDeal,
    removeDealRelationHandler,
    removeDealRelationApiResponse,
    getByIdHandler,
    getByIdApiResponse,
    getBatchHandler,
    getBatchResponse,
    updateServiceProvidersHandler,
    updateBatchResponse
  };
};
