import { StatusCode } from "@elphi/types";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { branchRepRelationApi } from "../redux/v2/rolodex";

export const useRolodexBranchRepRelation = () => {
  const [branchRepApi, branchRepResponse] =
    branchRepRelationApi.useLazyGetDealBatchQuery();
  const { errorToast } = useElphiToast();

  const branchRepState = useSelector(
    (state: RootState) => state.branchRepRelation
  );

  const getBranchRepByDealIdHandler = (
    dealId: string,
    preferCacheValue?: boolean
  ) => {
    branchRepApi({ dealId }, preferCacheValue)
      .then((r) => responseHandler(r as RTKResponse<typeof r.data>))
      .then((r) => {
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to get branch representative relations",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  return {
    getBranchRepByDealIdHandler,
    branchRepResponse,
    branchRepState
  };
};
