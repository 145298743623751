import {
  Box,
  Flex,
  Heading,
  Skeleton,
  SkeletonText,
  Text
} from "@chakra-ui/react";
import { ServiceProviderEntityType } from "@elphi/types";
import { groupBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { MenuOptions } from "../../../../common-components/menu-options/MenuOptions";
import { NONE } from "../../../../constants/common";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import ScrollableSections, { SectionProps } from "../../../ScrollableSections";
import { createSelectOptions } from "../../../utils/formUtils";
import { serviceProviderEntityTypeOptionMap } from "../../configuration/tabs/service-domain-configuration/modal/modal.utils";
import { ServiceProviderCard } from "./card/ServiceProviderCard";
import { GroupByOptions, groupByOptionsMap } from "./card/utils.ts/card.utils";
import { RolodexServiceProviderCard } from "./serviceProviderCard.types";
import { serviceProviderListHooks } from "./serviceProviderList.hooks";
export const ServiceProviderListCard = (props: {
  dealId: string;
  handleToggleEditModal: (selected: RolodexServiceProviderCard) => void;
  handleToggleRemoveModal: (selected: RolodexServiceProviderCard) => void;
  isLoading: boolean;
}) => {
  const { handleToggleEditModal, handleToggleRemoveModal, isLoading } = props;
  const { filteredItems, configurationState } = serviceProviderListHooks();
  const { heightOffsetInPx } = useWindowDimensions();
  const [selectedGroupBy, setSelectedGroupBy] = useState<string[]>();

  useEffect(() => {
    if (selectedGroupBy?.length) {
      handleGroupBy(selectedGroupBy);
    }
  }, [filteredItems.length]);

  const groupedData = useMemo(() => {
    const selectedOption = selectedGroupBy?.at(0);
    if (selectedOption === GroupByOptions.ServiceDomain) {
      return groupBy(filteredItems, (item) => item.domainConfigurationId);
    }
    if (selectedOption === GroupByOptions.EntityType) {
      return groupBy(filteredItems, (item) => item.entityType);
    }
    return undefined;
  }, [selectedGroupBy, filteredItems]);

  const handleGroupBy = (selected: string[]) => {
    const selectedOption = selected.at(0);
    !selectedOption
      ? setSelectedGroupBy([])
      : setSelectedGroupBy([selectedOption]);
  };

  const getGroupKey = (key: string) => {
    const selectedOption = selectedGroupBy?.at(0);
    if (selectedOption === GroupByOptions.ServiceDomain) {
      return configurationState?.entities?.[key]?.name || key;
    }
    if (selectedOption === GroupByOptions.EntityType) {
      return (
        serviceProviderEntityTypeOptionMap[key as ServiceProviderEntityType] ||
        key
      );
    }
    return key;
  };

  const sections = Object.entries(groupedData || {}).map(
    ([key, items], index): SectionProps => {
      return {
        index,
        header: <Heading>{getGroupKey(key)}</Heading>,
        body: (
          <ServiceProviderCards
            items={items}
            isLoading={isLoading}
            handleToggleEditModal={handleToggleEditModal}
            handleToggleRemoveModal={handleToggleRemoveModal}
          />
        )
      };
    }
  );

  return (
    <Box width={"100%"}>
      <Flex minWidth="max-content" alignItems="center" gap={2}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          ml={4}
          mr={2}
          mb={2}
        >
          <Text mr={2} color={"gray.500"}>
            GROUP BY
          </Text>
          <MenuOptions
            listContainerWidth={220}
            customKey={"groupBy"}
            onChange={handleGroupBy}
            title={groupByOptionsMap[selectedGroupBy?.at(0) || NONE]}
            options={createSelectOptions(GroupByOptions, groupByOptionsMap)}
            isSingle={true}
            selected={selectedGroupBy}
          />
        </Flex>
      </Flex>
      <Box maxHeight={heightOffsetInPx(460)} overflow={"auto"}>
        {groupedData ? (
          <ScrollableSections
            customKey="serviceProviders"
            sections={sections}
          />
        ) : (
          <ServiceProviderCards
            items={filteredItems}
            isLoading={isLoading}
            handleToggleEditModal={handleToggleEditModal}
            handleToggleRemoveModal={handleToggleRemoveModal}
          />
        )}
      </Box>
    </Box>
  );
};

const ServiceProviderCards = (props: {
  items: RolodexServiceProviderCard[];
  isLoading: boolean;
  handleToggleRemoveModal: (selected: RolodexServiceProviderCard) => void;
  handleToggleEditModal: (selected: RolodexServiceProviderCard) => void;
}) => {
  return (
    <Flex wrap={"wrap"}>
      {props.isLoading ? (
        <CardsSkeleton />
      ) : (
        props.items.map((data, index) => {
          return (
            <Box key={index} pr={2} pb={2}>
              <ServiceProviderCard
                data={data}
                onToggleRemove={() => {
                  props.handleToggleRemoveModal(data);
                }}
                onToggleEdit={() => {
                  props.handleToggleEditModal(data);
                }}
              />
            </Box>
          );
        })
      )}
    </Flex>
  );
};

const CardsSkeleton = () => {
  const skeleton = useMemo(() => {
    return Array(10)
      .fill(0)
      .map((_, i) => {
        return (
          <Box key={i} pr={2} pb={2}>
            <Skeleton key={i} width={"400px"} height={"80px"}>
              <SkeletonText />
            </Skeleton>
          </Box>
        );
      });
  }, []);
  return <>{skeleton}</>;
};
