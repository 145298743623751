import { Box, BoxProps } from "@chakra-ui/react";
import {
  DocumentPackageOrder,
  FirebaseCollections,
  OrderStatusType
} from "@elphi/types";
import { isEqual } from "lodash";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ElphiSpinner from "../../../common-components/loading-spinner/components/ElphiSpinner";
import { DemoComponent } from "../../../components/DemoComponent";
import {
  LinkTemplate,
  TemplateNotification
} from "../../../components/notifications/Template";
import { SelectOrganizationList } from "../../../components/platform/tabs/organization/SelectOrganization";
import { useElphiToast } from "../../../components/toast/toast.hook";
import { AppConfig } from "../../../config/appConfig";
import { printDateTime } from "../../../firebase/firebase.utils";
import { auth } from "../../../firebase/firebaseConfig";
import { useAuthListener } from "../../../hooks/authState.hooks";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";
import { useDealPartyRelationHooks } from "../../../hooks/dealpartyrelation.hooks";
import { useDocumentConfigurationHooks } from "../../../hooks/documentConfiguration.hooks";
import { useDocumentPackageOrderHooks } from "../../../hooks/documentPackageOrder.hooks";
import { useFirestoreListener } from "../../../hooks/listener.hook";
import { PLATFORM_ORG_ID, useOrgHooks } from "../../../hooks/org.hooks";
import { usePartyHooks } from "../../../hooks/party.hooks";
import { useTaskConfigurationHooks } from "../../../hooks/taskConfiguration.hooks";
import { useUsersnap } from "../../../hooks/usersnap.hooks";
import useWindowDimensions from "../../../hooks/windowDimensions";
import { RootState } from "../../../redux/store";
import { assetAction } from "../../../redux/v2/asset";
import { creditReportAction } from "../../../redux/v2/credit-report";
import { dealCalendarAction } from "../../../redux/v2/deal-calendar";
import dealPartyRelationAction from "../../../redux/v2/deal-party-relation/dealPartyRelation.action";
import dealPropertyRelationAction from "../../../redux/v2/deal-property-relation/dealPropertyRelation.action";
import dealUserRelationAction from "../../../redux/v2/deal-user-relation/dealUserRelation.action";
import dealAction from "../../../redux/v2/deal/deal.action";
import { dealApi } from "../../../redux/v2/deal/deal.service";
import documentConfigurationAction from "../../../redux/v2/document-configuration/documentConfiguration.action";
import { documentPackageOrderAction } from "../../../redux/v2/document-package-order";
import { documentRuleAction } from "../../../redux/v2/document-rule";
import { losUserAction } from "../../../redux/v2/los-user";
import { orgAction } from "../../../redux/v2/organization/action";
import { partyAssetAction } from "../../../redux/v2/party-asset-relation";
import { partyGroupAction } from "../../../redux/v2/party-group";
import { partyRelationAction } from "../../../redux/v2/party-relation/partyRelation.action";
import { partyUserRelationAction } from "../../../redux/v2/party-user-relation";
import { partyValidationConfigurationAction } from "../../../redux/v2/party-validation-configuration";
import partyAction from "../../../redux/v2/party/party.action";
import propertyAction from "../../../redux/v2/property/property.action";
import { roleAction } from "../../../redux/v2/role";
import {
  branchRepRelationAction,
  serviceDomainConfigurationAction,
  serviceProviderAction
} from "../../../redux/v2/rolodex";
import { serviceProviderViewAction } from "../../../redux/v2/rolodex/service-provider-view";
import { snapshotAction } from "../../../redux/v2/snapshot";
import { statementAction } from "../../../redux/v2/statement";
import taskConfigurationAction from "../../../redux/v2/task-configuration/taskConfiguration.action";
import taskRuleAction from "../../../redux/v2/task-rule/taskRule.action";
import taskTemplateAction from "../../../redux/v2/task-template/taskTemplate.action";
import taskAction from "../../../redux/v2/task/task.action";
import PathVariables from "../../../routes/pathVariables";
import { removeNulls } from "../../../utils/filter.utils";
import { DashboardContainer } from "../containers/DashboardContainer";
import RequireAuthContainer from "../containers/RequireAuthContainer";
import { Signout } from "./Dashboard";
import LoginPage from "./LoginPage";
import { NavigateHandler } from "./NavigateHandler";
import { ResetPasswordPage } from "./ResetPasswordPage";
import { TestFeatureFlag } from "./TestFeatureFlag";

const AutoLogin = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const locationState = location?.state as LocationState;
  // const from = locationState?.from?.pathname ?? PathVariables.LoanPipeline;
  const [cookies] = useCookies();

  useEffect(() => {
    const autoLogin = async () => {
      if (cookies["_et"]) {
        return await auth.signInWithCustomToken(cookies["_et"] ?? "");
      } else {
        throw Error("failed to get token from url");
      }
    };

    autoLogin()
      .then(() => {
        //can add status here

        navigate(PathVariables.SelectOrganization, { replace: true });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return <ElphiSpinner />;
};

export const App = (props: BoxProps) => {
  const dispatch = useDispatch();
  const { manageUsersnap } = useUsersnap();
  const { getEntitiesData } = useDocumentPackageOrderHooks();
  const { partyRelationCallbackHandler } = usePartyHooks();
  const { taskConfigurationCallback } = useTaskConfigurationHooks();
  const { documentConfigurationCallback } = useDocumentConfigurationHooks();
  const { dealCalendarCallBack } = useDealCalendarHooks();

  const dealState = useSelector((state: RootState) => state.deal);
  const [getAdditionalDataForDeals] =
    dealApi.useLazyGetAdditionalDataForDealsQuery();
  const {
    dealPartyRelationAddCallbackHandler,
    dealPartyRelationRemoveCallbackHandler
  } = useDealPartyRelationHooks();
  const { selectedOrgId } = useOrgHooks();
  const { infoToast, toast } = useElphiToast();

  useEffect(() => {
    const clearKey = "clear_2";
    const cleared = localStorage.getItem(clearKey);
    if (!cleared) localStorage.clear();
    localStorage.setItem(clearKey, new Date().toISOString());
    manageUsersnap();
  }, []);
  const { isLoggedIn: shouldStart } = useAuthListener();
  //this handler should be moved to a propper file
  const documentPackageOrderCallbackHandler = (
    r: DocumentPackageOrder[]
  ): void => {
    const orders = r.filter(
      (v) =>
        v.userId === auth?.currentUser?.uid &&
        v.order?.status === OrderStatusType.Ready
    );
    if (orders.length) {
      orders.forEach((o) => {
        getEntitiesData({
          orders: [o] as ({
            id: string;
          } & Partial<DocumentPackageOrder>)[]
        });
      });
      infoToast({
        title: "package order is ready",
        description: `${orders.length} orders ready`
      });
    }
  };

  useFirestoreListener({
    shouldListen: shouldStart && !!selectedOrgId,
    options: {
      listener: {
        settings: AppConfig.listeners?.settings
      }
    },
    listeners: [
      {
        collection: FirebaseCollections.Organization,
        actionHandler: orgAction(dispatch),
        filter:
          selectedOrgId !== PLATFORM_ORG_ID
            ? {
                fieldPath: "id",
                opStr: "==",
                value: selectedOrgId
              }
            : undefined
      },
      {
        collection: FirebaseCollections.Snapshot,
        actionHandler: snapshotAction(dispatch)
      },
      {
        collection: FirebaseCollections.Role,
        actionHandler: roleAction(dispatch)
      },
      {
        collection: FirebaseCollections.Party,
        actionHandler: partyAction(dispatch)
      },
      {
        collection: FirebaseCollections.Property,
        actionHandler: propertyAction(dispatch)
      },
      {
        collection: FirebaseCollections.Deal,
        actionHandler: dealAction(dispatch, {
          add: (deals) => {
            const dealsId = deals
              .filter(
                (d) =>
                  printDateTime(d.createdAt) === printDateTime(d.modifiedAt)
              )
              .map((d) => d.id);
            const missingIds = dealsId
              .filter(removeNulls)
              .filter((p) => !dealState.ids.includes(p));
            missingIds.length && getAdditionalDataForDeals({ ids: missingIds });
          }
        })
      },
      {
        collection: FirebaseCollections.Asset,
        actionHandler: assetAction(dispatch)
      },
      {
        collection: FirebaseCollections.PartyAsset,
        actionHandler: partyAssetAction(dispatch)
      },
      {
        collection: FirebaseCollections.Statement,
        actionHandler: statementAction(dispatch)
      },
      {
        collection: FirebaseCollections.LOSUser,
        actionHandler: losUserAction(dispatch)
      },
      {
        collection: FirebaseCollections.DealPartyRelation,
        actionHandler: dealPartyRelationAction(dispatch, {
          add: dealPartyRelationAddCallbackHandler,
          remove: dealPartyRelationRemoveCallbackHandler
        })
      },

      {
        collection: FirebaseCollections.PartyRelation,
        actionHandler: partyRelationAction(dispatch, {
          add: partyRelationCallbackHandler
        })
      },
      {
        collection: FirebaseCollections.DealProperty,
        actionHandler: dealPropertyRelationAction(dispatch)
      },
      {
        collection: FirebaseCollections.DealUser,
        actionHandler: dealUserRelationAction(dispatch, {
          add: (du) => {
            const newUserAssignments = du.filter(
              (v) =>
                v.userId === auth?.currentUser?.uid &&
                isEqual(v.createdAt, v.modifiedAt)
            );
            if (newUserAssignments.length) {
              toast({
                isClosable: true,
                duration: 6000,
                position: "top-right",
                render: (props) => {
                  const { selectedOrgId } = useOrgHooks();
                  return (
                    <TemplateNotification
                      onClose={props.onClose}
                      title={`You have been assigned to ${
                        newUserAssignments.length
                      } ${newUserAssignments.length > 1 ? "deals" : "deal"}`}
                      boxProps={{
                        bgColor: "blue.400"
                      }}
                      body={
                        <Box
                          zIndex="10"
                          bgColor="blue.400"
                          borderRadius={"8px"}
                          color="white"
                          p="10px"
                        >
                          {newUserAssignments.map((du, i) => {
                            return (
                              <LinkTemplate
                                key={i}
                                btnLabel={`Click to view deal ${du.dealId}`}
                                href={`/${selectedOrgId}${PathVariables.WorksheetPath}/${du.dealId}`}
                                tooltipLabel={""}
                              />
                            );
                          })}
                        </Box>
                      }
                    />
                  );
                }
              });
            }
          }
        })
      },
      {
        collection: FirebaseCollections.PartyUser,
        actionHandler: partyUserRelationAction(dispatch, {
          add: (pu) => {
            const newUserAssignments = pu.filter(
              (v) => v.userId === auth?.currentUser?.uid
            );
            if (newUserAssignments.length) {
              toast({
                isClosable: true,
                duration: 6000,
                position: "top-right",
                render: (props) => {
                  const { selectedOrgId } = useOrgHooks();
                  return (
                    <TemplateNotification
                      onClose={props.onClose}
                      title={`You have been assigned to ${
                        newUserAssignments.length
                      } ${newUserAssignments.length > 1 ? "parties" : "party"}`}
                      boxProps={{
                        bgColor: "pink.400"
                      }}
                      body={
                        <Box
                          zIndex="10"
                          bgColor="pink.400"
                          borderRadius={"8px"}
                          color="white"
                          p="10px"
                        >
                          {newUserAssignments.map((pu, i) => {
                            return (
                              <LinkTemplate
                                key={i}
                                btnLabel={`Click to view party ${pu.partyId}`}
                                href={`/${selectedOrgId}${PathVariables.PartiesPipeline}?party=${pu.partyId}`}
                                tooltipLabel={""}
                              />
                            );
                          })}
                        </Box>
                      }
                    />
                  );
                }
              });
            }
          }
        })
      },
      {
        collection: FirebaseCollections.CreditScore,
        actionHandler: creditReportAction(dispatch)
      },
      {
        collection: FirebaseCollections.Task,
        actionHandler: taskAction(dispatch)
      },
      {
        collection: FirebaseCollections.TaskRuleTemplate,
        actionHandler: taskRuleAction(dispatch)
      },
      {
        collection: FirebaseCollections.TaskTemplate,
        actionHandler: taskTemplateAction(dispatch)
      },
      {
        collection: FirebaseCollections.TaskConfiguration,
        actionHandler: taskConfigurationAction(
          dispatch,
          taskConfigurationCallback
        )
      },
      {
        collection: FirebaseCollections.DocumentConfiguration,
        actionHandler: documentConfigurationAction(
          dispatch,
          documentConfigurationCallback
        )
      },
      {
        collection: FirebaseCollections.DocumentRuleTemplate,
        actionHandler: documentRuleAction(dispatch)
      },
      {
        collection: FirebaseCollections.DocumentPackageOrder,
        actionHandler: documentPackageOrderAction(dispatch, {
          add: documentPackageOrderCallbackHandler,
          update: documentPackageOrderCallbackHandler
        })
      },
      {
        collection: FirebaseCollections.RolodexServiceDomainConfiguration,
        actionHandler: serviceDomainConfigurationAction(dispatch)
      },
      {
        collection: FirebaseCollections.DealCalendarView,
        actionHandler: dealCalendarAction(dispatch, dealCalendarCallBack)
      },
      {
        collection: FirebaseCollections.RolodexServiceProvider,
        actionHandler: serviceProviderAction(dispatch)
      },
      {
        collection: FirebaseCollections.RolodexServiceProviderView,
        actionHandler: serviceProviderViewAction(dispatch)
      },
      {
        collection: FirebaseCollections.RolodexBranchRepresentativeRelation,
        actionHandler: branchRepRelationAction(dispatch)
      },
      {
        collection: FirebaseCollections.PartyGroup,
        actionHandler: partyGroupAction(dispatch)
      },
      {
        collection: FirebaseCollections.PartyValidationConfiguration,
        actionHandler: partyValidationConfigurationAction(dispatch)
      }
    ]
  });
  const { width, height } = useWindowDimensions();

  return (
    <Box w={width} h={height} {...props}>
      <BrowserRouter>
        <React.Suspense fallback={<ElphiSpinner />}>
          <Routes>
            <Route
              path={"/test-fl"}
              element={<TestFeatureFlag />}
              key={"test-fl"}
            />
            <Route
              path={"/auto-login"}
              element={<AutoLogin />}
              key={"auto-login"}
            />
            <Route
              path="/"
              element={<NavigateHandler location={"login"} />}
              key={"root"}
            />

            <Route
              path=""
              element={<NavigateHandler location={"login"} />}
              key={"empty"}
            />
            <Route path="/login" element={<LoginPage />} key={"login"} />
            <Route
              path="/reset"
              element={<ResetPasswordPage />}
              key={"reset"}
            />
            <Route
              path={"/demo-component"}
              element={<DemoComponent />}
              key={"demo-component"}
            />
            <Route
              path={"/select-organization"}
              element={<SelectOrganizationList op="current-tab" />}
              key={"select-organization"}
            />
            <Route path={"/signout"} element={<Signout />} key={"signout"} />

            <Route
              path={"/:orgId/*"}
              element={
                <RequireAuthContainer>
                  <DashboardContainer />
                </RequireAuthContainer>
              }
              key={"dashboard"}
            />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Box>
  );
};
