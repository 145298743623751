import { Text } from "@chakra-ui/react";
import { EMPTY, NOT_AVAILABLE } from "../../../../constants/common";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import ModalContainer from "../../../modal-container/ModalContainer";
import { RolodexServiceProviderCard } from "../list-card/serviceProviderCard.types";

export const ServiceProviderRemoveModal = (props: {
  show: boolean;
  onClose: () => void;
  data: RolodexServiceProviderCard;
}) => {
  const { show, onClose, data } = props;
  const {
    selectedDeal,
    removeDealRelationHandler,
    removeDealRelationApiResponse
  } = useServiceProviderHooks();
  const loanIdentifier = selectedDeal
    ? selectedDeal.LoanIdentifier
    : NOT_AVAILABLE;
  const dealId = selectedDeal ? selectedDeal.id : EMPTY;

  return (
    <ModalContainer
      isShow={show}
      onCloseModal={onClose}
      header={`Removing a Service Provider: ${data.domainConfigurationName}`}
      body={
        <Text>
          Are you sure want to remove {data.companyName} | {data.branchName} |{" "}
          {data.repName} from deal {loanIdentifier} ?
        </Text>
      }
      submit={{
        isLoading: removeDealRelationApiResponse.isLoading,
        onConfirm: () => {
          removeDealRelationHandler({
            companyId: data.companyId,
            branchId: data.branchId,
            representativeId: data.repId,
            dealId
          }).finally(() => onClose());
        }
      }}
    />
  );
};
