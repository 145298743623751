import { RolodexConfiguration, RolodexFields, StatusCode } from "@elphi/types";
import { removeEmptyValues } from "@elphi/utils/src/common.utils";
import { useDispatch, useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { useElphiToast } from "../components/toast/toast.hook";
import { RootState } from "../redux/store";
import { useRTKPagination } from "../redux/v2/hooks";
import {
  serviceDomainConfigurationApi,
  serviceDomainConfigurationSlice
} from "../redux/v2/rolodex";
import { FieldsWeight, getRankedData } from "../utils/ranked.utils";

export const useRolodexConfiguration = () => {
  const dispatch = useDispatch();
  const { errorToast, successToast } = useElphiToast();

  const configurationState = useSelector(
    (state: RootState) => state.serviceDomainConfiguration
  );

  const setSelectedConfiguration = (id: string) =>
    dispatch(serviceDomainConfigurationSlice.actions.selectedId({ id }));

  const selectedConfiguration = useSelector((state: RootState) => {
    const selected =
      state.serviceDomainConfiguration.selectedId &&
      state.serviceDomainConfiguration.entities[
        state.serviceDomainConfiguration.selectedId
      ];
    return selected || undefined;
  });

  const [searchApi, searchResponse] =
    serviceDomainConfigurationApi.useLazySearchQuery();

  const paginationHandler = useRTKPagination({
    entityState: configurationState,
    useLazyPaginateQuery: serviceDomainConfigurationApi.useLazyPaginateQuery
  });

  const [getPaginateConfigurations, getPaginateConfigurationsResponse] =
    serviceDomainConfigurationApi.useLazyPaginateQuery();

  const [updateBatchApi, updateBatchResponse] =
    serviceDomainConfigurationApi.useBatchUpdateMutation();

  const [updateSystemApi, updateSystemResponse] =
    serviceDomainConfigurationApi.useSystemUpdateMutation();

  const [deleteApi, deleteResponse] =
    serviceDomainConfigurationApi.useDeleteMutation();

  const [createApi, createResponse] =
    serviceDomainConfigurationApi.useCreateMutation();

  const createConfiguration = async (
    r: Pick<RolodexConfiguration, "name" | "fields" | "entityType">
  ) => {
    await createApi({
      ...r,
      status: "disabled"
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Configuration created",
            description: `Configuration: ${r.data?.id}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to create configuration",
            description: r.data?.description
          });
        }
      });
  };

  const cloneConfiguration = async (
    r: Pick<RolodexConfiguration, "name" | "entityType">
  ) => {
    if (!selectedConfiguration) {
      return;
    }
    await createApi({
      fields: selectedConfiguration.fields,
      ...r,
      status: "disabled"
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Configuration cloned",
            description: `Configuration: ${r.data?.id}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to clone configuration",
            description: r.data?.description
          });
        }
      });
  };

  const deleteConfiguration = async (id: string) => {
    await deleteApi(id)
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Configuration Deleted",
            description: `Configuration: ${r.data?.id}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to delete configuration",
            description: r.data?.description
          });
        }
      });
  };

  const updateConfigurationBatch = async (r: {
    [id: string]: Partial<RolodexConfiguration>;
  }) => {
    const configurations = removeEmptyValues(r);
    if (!configurations?.length) {
      return null;
    }

    return await updateBatchApi({ configurations })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Configuration Updated",
            description: `total configurations updated: ${r.data?.batch?.length}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update batch",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const updateFieldsConfiguration = async (r: {
    id: string;
    fields: Partial<RolodexFields>;
  }) => {
    const configurations = removeEmptyValues(r);
    if (!configurations?.length) {
      return null;
    }

    return await updateSystemApi({
      id: r.id,
      fields: r.fields
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Configuration fields updated",
            description: `configurations updated: ${r.data?.id}`
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Failed to update fields",
            description: r.data?.description
          });
        }
        return r;
      });
  };

  const dataRank: FieldsWeight<RolodexConfiguration> = {
    name: 30
  };

  const rankedSort = (query: string) => {
    return Object.values(configurationState.entities).sort((a, b) => {
      const rankA = getRankedData(query, a, dataRank);
      const rankB = getRankedData(query, b, dataRank);

      if (rankA < rankB) {
        return 1;
      }
      return -1;
    });
  };

  return {
    selectedConfiguration,
    setSelectedConfiguration,
    configurationState,
    createConfiguration,
    createResponse,
    cloneConfiguration,
    updateConfigurationBatch,
    updateBatchResponse,
    updateFieldsConfiguration,
    updateSystemResponse,
    deleteConfiguration,
    deleteResponse,
    paginationHandler,
    searchApi,
    searchResponse,
    dataRank,
    rankedSort,
    getPaginateConfigurations,
    getPaginateConfigurationsResponse
  };
};
