import { Box, Flex, Text } from "@chakra-ui/react";
import {
  DealCalendarDateType,
  DealCalendarType,
  formatNumberWithCommas
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { DATES_FORMATS, elphiDate } from "@elphi/utils";
import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { CustomCollapse, CustomTooltip } from "../../../common-components";
import { useDealCalendarHooks } from "../../../hooks/dealCalendar.hooks";
import { DayToolTipLabel } from "./DayToolTipLabel";

export type DaysToolTipLabelProps = { currentDay: Date };

type DayToolTipLabelBodyProps = {
  dateDay: DealCalendarDateType;
} & DaysToolTipLabelProps;

const CustomBodyContent = ({
  deals
}: {
  deals: Partial<DealCalendarType>[];
}) => (
  <>
    {deals?.map((dealCalendar: DealCalendarType, index: number) => (
      <DayToolTipLabel key={index} index={index} dealCalendar={dealCalendar} />
    ))}
  </>
);

const dealCalendarMilestone: DotNestedKeys<DealCalendarType> = "milestone";

const DayToolTipLabelBody = ({
  dateDay,
  currentDay
}: DayToolTipLabelBodyProps) => {
  const milesoneToDeals = groupBy(dateDay?.deals, dealCalendarMilestone);

  return (
    <Flex w={"350px"} h={"100%"} borderRadius={"8px"} flexDir={"column"}>
      <Flex w={"100%"} flexDir={"column"} overflowY={"auto"}>
        <Flex flexDir={"row"} justify={"space-between"} mb={"6px"} mt={"6px"}>
          <Flex>
            <Text fontWeight={"700"} fontSize={"16"}>
              {currentDay.toLocaleString("en-US", { weekday: "long" })}
            </Text>
          </Flex>
          <Flex>
            <Text fontWeight={"700"} fontSize={"16"}>
              {elphiDate(currentDay).dateIndex()}
            </Text>
          </Flex>
        </Flex>
        {Object.entries(milesoneToDeals).map(([key, value], index) => (
          <Box key={`containerCollapse${index}`} mb={"5px"}>
            <CustomCollapse
              key={index}
              title={key}
              customBody={
                <CustomBodyContent key={`${index}-BodyContent`} deals={value} />
              }
            />
          </Box>
        ))}
      </Flex>
      <Flex justify={"space-between"} mt={"auto"} flexDir={"row"}>
        <Flex>
          <Text fontWeight={"700"} fontSize={"16"}>
            {dateDay.totalDealsCalendar} closings
          </Text>
        </Flex>
        <Flex>
          <Text fontWeight={"700"} fontSize={"16"}>
            {!!dateDay?.totalRequestedLoanAmount &&
              `$
              ${formatNumberWithCommas(
                dateDay.totalRequestedLoanAmount.toString()
              )}`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export const DaysToolTipLabel = ({ currentDay }: DaysToolTipLabelProps) => {
  const { dates, selectedDateType, hoverDate, setHoverDate } =
    useDealCalendarHooks();
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseLeave = () => setHoverDate({ hoverDate: undefined });

  const currentDate = elphiDate(currentDay).format(DATES_FORMATS.YYYY_MM_DD);

  const [currentDateDay, setCurrentDateDay] = useState<DealCalendarDateType>(
    dates?.[selectedDateType]?.[currentDate] || {}
  );

  useEffect(() => {
    const dateDay = dates?.[selectedDateType]?.[currentDate] || {};
    setCurrentDateDay(dateDay);

    setShowTooltip(
      !!dateDay?.deals?.length &&
        currentDay.toDateString() === hoverDate?.toDateString()
    );
  }, [hoverDate]);

  const id = `${selectedDateType}_${currentDate}`;

  return (
    <CustomTooltip
      key={id}
      id={id}
      showTooltip={showTooltip}
      handleMouseLeave={handleMouseLeave}
      type={"full"}
    >
      <DayToolTipLabelBody dateDay={currentDateDay} currentDay={currentDay} />
    </CustomTooltip>
  );
};
