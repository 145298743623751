import { RolodexBranchRepresentativeRelation } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import { AppConfig } from "../../../../config/appConfig";
import serviceBuilder from "../../builders/api.builder";
import { branchRepRelationAdapter } from "./branchRepRelation.adapter";

export const baseBranchRepRelationApi = createApi({
  keepUnusedDataFor: AppConfig.rtk.cache.keepUnusedDataFor,
  reducerPath: "branchRepRelationApi",
  tagTypes: ["ServiceProvider", "BranchRepRelation"],
  baseQuery: serviceBuilder.baseQuery({
    route: "rolodex-branch-rep-relation"
  }),
  endpoints: serviceBuilder.crudEndpoints({
    entityAdapter: branchRepRelationAdapter
  })
});

export const branchRepRelationApi = baseBranchRepRelationApi.injectEndpoints({
  endpoints: (builder) => ({
    getDealBatch: builder.query<
      EntityState<RolodexBranchRepresentativeRelation>,
      { dealId: string }
    >({
      query: (r) => {
        return {
          url: `/get-deal-batch/${r.dealId}`,
          method: "GET"
        };
      },
      transformResponse: (response: {
        results: RolodexBranchRepresentativeRelation[];
      }) => {
        const adapter = branchRepRelationAdapter.addMany(
          branchRepRelationAdapter.getInitialState(),
          response.results
        );
        return adapter;
      }
    })
  })
});
