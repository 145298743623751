import { Button, Flex } from "@chakra-ui/react";
import { useState } from "react";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../constants/common";
import { usePartyGroup } from "../../../hooks/partyGroup.hooks";
import PartyGroupSearch from "../../party/partyGroup/PartyGroupSearch";
import { PartyGroupModal } from "./modal/PartyGroupModal";
import { PartyGroupTable } from "./table/PartyGroupTable";
import { Toolbar } from "./toolBar/ToolBar";

export const PartyGroup = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { createPartyGroup, createResponse, setPartyGroupId, partyGroupState } =
    usePartyGroup();

  return (
    <>
      <Flex alignContent={"center"}>
        <PartyGroupSearch
          currentValue={
            partyGroupState.selectedSearchResultId?.toString() || EMPTY
          }
          isClearable={true}
          onSelect={(id) => {
            setPartyGroupId(id);
          }}
        />
        <Button
          onClick={() => setIsModalVisible(true)}
          float="right"
          {...elphiTheme.components.light.button.primary}
        >
          Create Party Group
        </Button>
      </Flex>
      <Toolbar />
      <PartyGroupTable />
      <PartyGroupModal
        isShow={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        headerText="Create Party Group"
        acknowledgeButtonText="Confirm"
        acknowledgeAction={createPartyGroup}
        isLoading={createResponse.isLoading}
      />
    </>
  );
};
