import { RolodexServiceProvider } from "@elphi/types";
import { useEffect, useMemo, useState } from "react";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import { OnChangeInput } from "../../../../../form-builder/FormBuilder";
import { providerFormSectionBuilder } from "./providersFormStep.utils";

export const useProviderFormStepHooks = <
  T extends RolodexServiceProvider
>(props: {
  state: Partial<T>;
  onChangeState: (v: OnChangeInput) => void;
  isReadOnly: boolean;
}) => {
  const { state, onChangeState, isReadOnly } = props;
  const { selectedConfiguration } = useRolodexConfiguration();
  const [isStepValid, setStepValid] = useState<boolean>(false);
  const formSection = useMemo(() => {
    return providerFormSectionBuilder({
      state,
      onChange: onChangeState,
      configuration: selectedConfiguration,
      isReadOnly
    });
  }, [state, selectedConfiguration, isReadOnly]);

  useEffect(() => {
    setStepValid(formSection.inputs.every((x) => x?.isValid));
  }, [state]);

  return {
    formSection,
    isStepValid,
    setStepValid
  };
};
