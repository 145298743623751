import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  useDisclosure
} from "@chakra-ui/react";
import { debounce, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { FilterMenuOptions } from "../../../common-components/menu-options/FilterMenuOptions";
import { useFilterMenuHook } from "../../../common-components/menu-options/filter-menu-hook";
import { AppConfig } from "../../../config/appConfig";
import { EMPTY } from "../../../constants/common";
import { ServiceProviderListCard } from "./list-card/ServiceProviderListCard";
import { statusOptions } from "./list-card/card/utils.ts/card.utils";
import { RolodexServiceProviderCard } from "./list-card/serviceProviderCard.types";
import { serviceProviderListHooks } from "./list-card/serviceProviderList.hooks";
import { ServiceProviderCreateModal } from "./modal/ServiceProviderCreateModal";
import { ServiceProviderEditModal } from "./modal/ServiceProviderEditModal";
import { ServiceProviderRemoveModal } from "./modal/ServiceProviderRemoveModal";

export const ServiceProviderContainer = (props: { dealId: string }) => {
  const { dealId } = props;
  const {
    configurationOptions,
    setCurrentFilter,
    currentFilter,
    getPaginateConfigurations,
    getAllHandler,
    getAllResponse
  } = serviceProviderListHooks();
  const filterMenuHook = useFilterMenuHook();
  const { clearFilters } = filterMenuHook;
  const { isOpen: isOpenAddModal, onToggle: onToggleAddModal } =
    useDisclosure();
  const { isOpen: isOpenEditModal, onToggle: onToggleEditModal } =
    useDisclosure();
  const { isOpen: isOpenRemoveModal, onToggle: onToggleRemoveModal } =
    useDisclosure();
  const [selectedProvider, setSelectedProvider] =
    useState<RolodexServiceProviderCard>();
  const [search, setSearch] = useState<string>(EMPTY);

  useEffect(() => {
    getPaginateConfigurations({
      limit: 300
    });
    setCurrentFilter({
      entityId: dealId
    });
  }, []);

  useEffect(() => {
    if (currentFilter?.entityId) {
      getAllHandler();
    }
  }, [currentFilter?.entityId]);

  const handleToggleEditModal = (selected: RolodexServiceProviderCard) => {
    setSelectedProvider(selected);
    onToggleEditModal();
  };

  const handleToggleRemoveModal = (selected: RolodexServiceProviderCard) => {
    setSelectedProvider(selected);
    onToggleRemoveModal();
  };

  const searchDebounce = debounce((query: string) => {
    setCurrentFilter({
      ...currentFilter,
      freeText: query
    });
  }, AppConfig.search.debounceRate);

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      ...currentFilter,
      domainConfigurations: [],
      statuses: []
    });
  };
  const isLoading = getAllResponse.isLoading || getAllResponse.isFetching;

  return (
    <>
      <Flex>
        <InputGroup maxWidth={400}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            variant="outline"
            placeholder={"Search"}
            fontSize={12}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchDebounce(e.target.value);
            }}
          />
          <InputRightElement hidden={!search}>
            <CloseIcon
              w={4}
              h={4}
              cursor={"pointer"}
              color="gray.500"
              onClick={() => {
                setSearch(EMPTY);
                setCurrentFilter({
                  ...currentFilter,
                  freeText: EMPTY
                });
              }}
            />
          </InputRightElement>
        </InputGroup>
        <Spacer />
        <Box pr={"10px"}>
          <Button
            {...elphiTheme.components.light.button.primary}
            onClick={onToggleAddModal}
          >
            Add a Service Provider
          </Button>
        </Box>
      </Flex>
      <Flex minWidth="max-content" alignItems="center" gap={2} mt={2}>
        <FilterMenuOptions
          customKey={"domainFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Service Domain"}
          options={configurationOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              domainConfigurations: items
            });
          }}
          selected={currentFilter?.domainConfigurations}
          showClearAll
        />
        <FilterMenuOptions
          customKey={"statusFilter"}
          maxHeight={"300px"}
          scrollHeight={"300px"}
          filterMenuHook={filterMenuHook}
          title={"Service Provider Status"}
          options={statusOptions}
          showSearchBar={true}
          onChange={(items: string[]) => {
            setCurrentFilter({
              ...currentFilter,
              statuses: items
            });
          }}
          selected={currentFilter?.statuses}
          showClearAll
        />
        <Button
          mr={1}
          background={"transparent"}
          fontSize={14}
          fontWeight={"normal"}
          onClick={handleClearClick}
          isDisabled={
            isEmpty(currentFilter?.domainConfigurations) &&
            isEmpty(currentFilter?.statuses)
          }
        >
          Clear filters
        </Button>
      </Flex>
      <Flex>
        <ServiceProviderListCard
          dealId={props.dealId}
          handleToggleEditModal={handleToggleEditModal}
          handleToggleRemoveModal={handleToggleRemoveModal}
          isLoading={isLoading}
        />
      </Flex>
      <ServiceProviderCreateModal
        isShow={isOpenAddModal}
        onClose={onToggleAddModal}
        dealId={props.dealId}
      />
      {selectedProvider && (
        <ServiceProviderEditModal
          dealId={props.dealId}
          data={{ ...selectedProvider }}
          isShow={isOpenEditModal}
          onClose={onToggleEditModal}
        />
      )}
      {selectedProvider && (
        <ServiceProviderRemoveModal
          data={selectedProvider}
          show={isOpenRemoveModal}
          onClose={onToggleRemoveModal}
        />
      )}
    </>
  );
};
