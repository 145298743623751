import { RolodexServiceProviderView } from "@elphi/types";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createSlice
} from "@reduxjs/toolkit";
import { keyBy, union } from "lodash";
import { serviceProviderViewAdapter } from "./serviceProviderView.adapter";
import { serviceProviderViewApi } from "./serviceProviderView.service";

export type ServiceProviderViewFilterState = Partial<{
  domainConfigurations: string[];
  statuses: string[];
  entityId: string;
  freeText: string;
}>;

export type ServiceProviderViewSliceState =
  EntityState<RolodexServiceProviderView> & {
    selectedId?: EntityId;
    current: {
      filter: ServiceProviderViewFilterState;
    };
  };

const initialState: ServiceProviderViewSliceState = {
  ids: [],
  entities: {},
  selectedId: undefined,
  current: {
    filter: {}
  }
};

export const serviceProviderViewSlice = createSlice({
  name: "serviceProviderView",
  initialState: serviceProviderViewAdapter.getInitialState(initialState),
  reducers: {
    update: serviceProviderViewAdapter.updateOne,
    remove: serviceProviderViewAdapter.removeOne,
    add: serviceProviderViewAdapter.addOne,
    upsert: serviceProviderViewAdapter.upsertOne,
    upsertMany: serviceProviderViewAdapter.upsertMany,
    removeMany: serviceProviderViewAdapter.removeMany,
    updateMany: serviceProviderViewAdapter.updateMany,
    setFilter: (
      state,
      action: PayloadAction<ServiceProviderViewFilterState>
    ) => {
      state.current.filter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      serviceProviderViewApi.endpoints.getAll.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(
          state.ids,
          payload.result.map((v) => v.id)
        );
        state.entities = {
          ...state.entities,
          ...keyBy(payload.result, "id")
        };
      }
    );
  }
});
