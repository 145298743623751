import { Party } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const totalStatementQualifyingBalanceFieldPath: ObjectKeyValidation<
  Party,
  ["aggregations", "TotalStatementQualifyingBalance"]
> = ["aggregations", "TotalStatementQualifyingBalance"];
export const backgroundReportExpirationDateFieldPath: ObjectKeyValidation<
  Party,
  ["aggregations", "BackgroundReportExpirationDate"]
> = ["aggregations", "BackgroundReportExpirationDate"];
export const partyTypeFieldPath: ObjectKeyValidation<Party, ["PartyType"]> = [
  "PartyType"
];

export const partyAddressLineTextFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "AddressLineText"]
> = ["Address", "AddressLineText"];

export const partyAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "AddressUnitIdentifier"]
> = ["Address", "AddressUnitIdentifier"];

export const partyCityNameFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "CityName"]
> = ["Address", "CityName"];

export const partyCountyNameFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "CountyName"]
> = ["Address", "CountyName"];

export const partyPostalCodeFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "PostalCode"]
> = ["Address", "PostalCode"];

export const partyStateNameFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "StateName"]
> = ["Address", "StateName"];

export const partyStateCodeFieldPath: ObjectKeyValidation<
  Party,
  ["Address", "StateCode"]
> = ["Address", "StateCode"];

export const groupIdPath: ObjectKeyValidation<Party, ["GroupId"]> = ["GroupId"];
