import { Flex } from "@chakra-ui/react";
import { PartyGroup } from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppConfig } from "../../../../config/appConfig";
import { auth } from "../../../../firebase/firebaseConfig";
import { useAuthStateChangeHook } from "../../../../hooks/authStateChange.hooks";
import { usePartyGroup } from "../../../../hooks/partyGroup.hooks";
import useWindowDimensions from "../../../../hooks/windowDimensions";
import { ElphiPaginationList } from "../../../elphi-list/ElphiList";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import { TableRowSizeComponent } from "../../../table/TableRowSizeComponent";
import { PartyGroupModal } from "../modal/PartyGroupModal";
import { PartyGroupRow } from "./PartyGroupRow";
import { headerCells } from "./tableHeaders";

const LIMIT = 10;
export const PartyGroupTable = () => {
  const [modalDetails, setModalDetails] = useState<{
    isShow: boolean;
    partyGroup: Partial<PartyGroup> | undefined;
  }>({ isShow: false, partyGroup: undefined });
  const {
    countApi,
    paginationHandler,
    partyGroupState,
    updatePartyGroupBatch,
    selectPartyGroup,
    unSelectPartyGroup,
    clonePartyGroup
  } = usePartyGroup();

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      countApi(undefined, true);
      if (partyGroupState?.ids.length <= LIMIT) {
        paginationHandler.next();
      }
    },
    deps: [auth.currentUser]
  });

  useAuthStateChangeHook({
    onAuthStateChange: (_) => {
      if (auth.currentUser) {
        paginationHandler.next();
      }
    },
    deps: []
  });

  const { syncState, state, onChange } = useFormBuilderStateHandler<{
    [id: string]: Partial<PartyGroup>;
  }>({
    initialState: {},
    callback: updatePartyGroupBatch,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const { heightOffsetInPx } = useWindowDimensions();

  const handleRowToggle = (r: { isChecked: boolean; id: string }) => {
    !r.isChecked ? unSelectPartyGroup(r.id) : selectPartyGroup(r.id);
  };

  const buildRowData = (id: EntityId, index: string | number) => {
    const data = partyGroupState.entities[id];
    if (!data) {
      return <></>;
    }

    return (
      <PartyGroupRow
        index={Number(index)}
        data={data}
        state={state}
        syncState={syncState}
        onChange={onChange}
        isSelected={partyGroupState.selectedIds.includes(id)}
        onSelect={(r) => handleRowToggle(r)}
        onClone={(r) => {
          setModalDetails({
            isShow: true,
            partyGroup: r.partyGroup
          });
        }}
      />
    );
  };

  return (
    <>
      <Flex width="100%">
        <ElphiPaginationList
          height={heightOffsetInPx(320)}
          tableName={"Party groups"}
          isLoading={paginationHandler.pageResponse.isLoading}
          rowStyle={{
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none"
          }}
          header={
            <TableRowSizeComponent
              bgColor="gray.100"
              row={headerCells}
              withBorderRightWidth={false}
              justify="space-between"
            />
          }
          pageSize={LIMIT}
          items={
            !partyGroupState.selectedSearchResultId
              ? partyGroupState.ids
              : [partyGroupState.selectedSearchResultId]
          }
          next={paginationHandler.next}
          hasMore={paginationHandler.pageData?.ids?.length === LIMIT}
          options={{
            showEndMessage: false,
            showWatching: false
          }}
          rowBuilder={buildRowData}
        />
      </Flex>
      <PartyGroupModal
        isShow={modalDetails.isShow}
        onClose={() =>
          setModalDetails({ isShow: false, partyGroup: undefined })
        }
        partyGroup={modalDetails.partyGroup}
        headerText="Clone Party Group"
        subHeaderText={`Cloning: id: ${modalDetails.partyGroup?.id}, ${modalDetails.partyGroup?.name}`}
        acknowledgeButtonText="Clone"
        acknowledgeAction={clonePartyGroup}
      />
    </>
  );
};
