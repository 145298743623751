import { Flex, Link, Text, Tooltip } from "@chakra-ui/react";
import {
  Deal,
  DealCalendarType,
  EntityParty,
  formatNumberWithCommas
} from "@elphi/types";
import { DotNestedKeys } from "@elphi/types/utils/flatten";
import { get, isEmpty, truncate, values } from "lodash";
import { useNavigate } from "react-router";
import { EMPTY } from "../../../constants/common";
import { WorksheetTab } from "../../../features/worksheet/worksheet.types";
import { useOrgHooks } from "../../../hooks/org.hooks";
import PathVariables from "../../../routes/pathVariables";
import { NavigateToDeal } from "../../deal/table/v2/DealTableRow";
import {
  ChecklistPink100Icon,
  DirectionsIcon,
  DollarGreen100Icon,
  HandHouseBlue100Icon,
  HouseOrange100Icon,
  HumanCyan100Icon,
  HumanPurple100Icon,
  MenuRightIcon
} from "../../icons";

export type DayToolTipLabelProps = {
  index: number;
  dealCalendar: DealCalendarType;
};

const partyFullName: DotNestedKeys<EntityParty> = "FullName";

export const DayToolTipLabel = ({
  dealCalendar,
  index
}: DayToolTipLabelProps) => {
  const navigate = useNavigate();
  const { selectedOrgId } = useOrgHooks();

  const getFullName = (item): string => {
    const fullName = get(item, partyFullName, "");
    if (isEmpty(fullName)) {
      const { FirstName = "", MiddleName = "", LastName = "" } = item;
      return `${FirstName} ${MiddleName} ${LastName}`.trim();
    }
    return fullName;
  };

  const truncateNames = (data) => {
    const firstItem = values(data)?.[0] || {};
    const itemsWithOutFirst = values(data).slice(1);

    const names = itemsWithOutFirst.map(getFullName);

    const fullName = getFullName(firstItem);

    const shortenedFullName =
      fullName.length > 12 ? `${truncate(fullName, { length: 12 })}` : fullName;

    const itemCount = names.length;

    const partiesPipelinePath = `../../${selectedOrgId?.toString()}${
      PathVariables.PartiesPipeline
    }?party=${firstItem.partyId}`;

    return (
      <>
        <Tooltip label={fullName && `new tab to: ${fullName}`}>
          <Link href={firstItem?.partyId && partiesPipelinePath} isExternal>
            {shortenedFullName || "--"}
          </Link>
        </Tooltip>
        <Tooltip label={names?.length > 0 && `${names.join(", ")}`}>
          {itemCount > 0 ? ` + ${itemCount}` : EMPTY}
        </Tooltip>
      </>
    );
  };

  const changeRouteToChecklist = () => {
    navigate(
      `/${selectedOrgId}${PathVariables.WorksheetPath}/${dealCalendar.id}/checklist`,
      {
        state: { title: WorksheetTab.Checklist },
        replace: true
      }
    );
  };

  return (
    <Flex
      key={index}
      flexDir={"column"}
      justify={"space-between"}
      padding={"6px"}
      borderRadius={"8px"}
      bgColor={"white"}
      h={"135px"}
      mb={"12px"}
    >
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <HandHouseBlue100Icon w={"18px"} h={"18px"} mr={"3px"} />
          <NavigateToDeal
            selectedOrgId={selectedOrgId}
            rowData={{
              deal: {
                LoanIdentifier: dealCalendar.LoanIdentifier,
                id: dealCalendar.id
              } as Partial<Deal>
            }}
          >
            <Text fontSize={"14px"}>{dealCalendar.LoanIdentifier}</Text>
          </NavigateToDeal>
        </Flex>
        <Flex alignItems={"center"}>
          <Text fontSize={"14px"}>
            {truncateNames({
              ...dealCalendar.primaryBorrowers,
              ...dealCalendar.secondaryBorrowers
            })}
          </Text>
          <HumanPurple100Icon w={"18px"} h={"18px"} ml={"3px"} />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <ChecklistPink100Icon w={"18px"} h={"18px"} mr={"3px"} />
          <Text fontSize={"14px"}>
            <Link onClick={changeRouteToChecklist} isExternal>
              {`Tasks: ${dealCalendar?.taskLength || 0}`}
            </Link>
          </Text>
        </Flex>
        <Flex alignItems={"center"}>
          <Text fontSize={"14px"}>
            {truncateNames({
              ...dealCalendar.primarySponsors,
              ...dealCalendar.secondarySponsors
            })}
          </Text>
          <HumanCyan100Icon w={"18px"} h={"18px"} ml={"3px"} />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <DollarGreen100Icon w={"18px"} h={"18px"} mr={"3px"} />
          <Text fontSize={"14px"}>
            {!!dealCalendar?.RequestedLoanAmount
              ? `$${formatNumberWithCommas(
                  dealCalendar.RequestedLoanAmount.toString()
                )}`
              : "--"}
          </Text>
        </Flex>
        <Flex alignItems={"center"}>
          <Text fontSize={"14px"}>{dealCalendar.LoanProgramType || "--"}</Text>
          <HouseOrange100Icon w={"18px"} h={"18px"} ml={"3px"} />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} alignItems={"center"}>
        <Flex alignItems={"center"}>
          <DirectionsIcon w={"18px"} h={"18px"} mr={"3px"} />
          <Text fontSize={"14px"}>{dealCalendar.milestone}</Text>
        </Flex>
        <Flex>
          <MenuRightIcon w={"24px"} h={"24px"} />
        </Flex>
      </Flex>
    </Flex>
  );
};
