import { Box, keyframes } from "@chakra-ui/react";

export type RightSideBarProps = {
  show: boolean;
  height?: string | number;
  children?: React.ReactNode;
};

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const RightSideBar = ({
  show,
  children,
  height = "100%"
}: RightSideBarProps) => (
  <Box
    height={height}
    position="fixed"
    right="0"
    bottom="0"
    width="362px"
    backgroundColor="white"
    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
    padding="4"
    zIndex={999}
    animation={show ? `${slideIn} 0.3s ease-out forwards` : "none"}
    display={show ? "block" : "none"}
  >
    {children}
  </Box>
);
