import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Collapse, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";

type OptionProps = {
  containerColor?: string;
  startingHeight?: number | string;
  endingHeight?: number | string;
  withScroll?: boolean;
};

export type CustomCollapseProps = {
  children?: React.ReactNode;
  customBody?: React.ReactNode;
  title?: string;
  options?: OptionProps;
};

export const CustomCollapse = ({
  children,
  customBody,
  title,
  options
}: CustomCollapseProps) => {
  const {
    containerColor = "gray.100",
    endingHeight,
    startingHeight,
    withScroll
  } = options || {};

  const [show, setShow] = useState(false);

  return (
    <Flex>
      <Box
        padding={"6px"}
        borderRadius={"8px"}
        bgColor={containerColor}
        w="100%"
      >
        <Flex
          onClick={() => setShow(!show)}
          justify={"space-between"}
          alignItems={"center"}
          mb={"6px"}
        >
          {title && (
            <Flex>
              <Text fontSize={"16px"}>{title}</Text>
            </Flex>
          )}
          <Flex>
            <Text>
              {show ? (
                <ChevronUpIcon w={"24px"} h={"24px"} />
              ) : (
                <ChevronDownIcon w={"24px"} h={"24px"} />
              )}
            </Text>
          </Flex>
        </Flex>
        <Collapse
          in={show}
          startingHeight={startingHeight}
          endingHeight={endingHeight}
          style={withScroll ? { overflowY: "auto" } : {}}
        >
          {children && (
            <Flex
              flexDir={"column"}
              justify={"space-between"}
              padding={"6px"}
              borderRadius={"8px"}
              bgColor={"white"}
              h={"135px"}
              mb={"12px"}
            >
              {children}
            </Flex>
          )}
          {!!customBody && customBody}
        </Collapse>
      </Box>
    </Flex>
  );
};
