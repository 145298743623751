import {
  BranchServiceProvider,
  CompanyServiceProvider,
  RepresentativeServiceProvider,
  RolodexServiceProvider,
  StatusCode
} from "@elphi/types";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { EMPTY } from "../../../../constants/common";
import { useRolodexConfiguration } from "../../../../hooks/rolodexConfiguration.hooks";
import { useServiceProviderHooks } from "../../../../hooks/rolodexServiceProvider.hooks";
import { useFormBuilderStateHandler } from "../../../form-builder/InputBuilder";
import ModalContainer from "../../../modal-container/ModalContainer";
import { WizardContainer } from "../../../wizard-container/WizardContainer";
import { RolodexServiceProviderCard } from "../list-card/serviceProviderCard.types";
import { EntitiesStepContent } from "../wizard/steps/EntitiesStepContent";
import { ProvidersFormStepContent } from "../wizard/steps/provider-step-content/ProvidersFormStepContent";
import {
  getBranchTemplate,
  getCompanyTemplate,
  getRepTemplate
} from "../wizard/steps/provider-step-content/providersFormStep.utils";

type EditModalProps = {
  dealId: string;
  isShow: boolean;
  onClose: () => void;
  data: Pick<
    RolodexServiceProviderCard,
    | "companyId"
    | "branchId"
    | "repId"
    | "domainConfigurationId"
    | "dealEntities"
  >;
};

const useServiceProviderForm = <T extends RolodexServiceProvider>() => {
  const { serviceProviderState, getByIdHandler } = useServiceProviderHooks();
  const [selectedProviderId, setSelectedProviderId] = useState<string>(EMPTY);
  const { onChange, state, setState } = useFormBuilderStateHandler<Partial<T>>({
    initialState: {}
  });

  useEffect(() => {
    if (selectedProviderId) {
      const provider = serviceProviderState.entities[selectedProviderId] as
        | T
        | undefined;
      if (!provider) {
        getByIdHandler({ id: selectedProviderId });
      }
      if (provider) {
        setState(cloneDeep(provider));
      }
    }
  }, [selectedProviderId, serviceProviderState.entities[selectedProviderId]]);

  return {
    onChange,
    state,
    setState,
    selectedProviderId,
    setSelectedProviderId
  };
};

export const ServiceProviderEditModal = (props: EditModalProps) => {
  const { isShow, onClose, data, dealId } = props;
  const { setSelectedConfiguration, selectedConfiguration } =
    useRolodexConfiguration();
  const { updateHandler, upsertResponse, selectedDeal } =
    useServiceProviderHooks();
  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);
  const [isProviderFormValid, setIsProviderFormValid] =
    useState<boolean>(false);

  const {
    onChange: onChangeCompanyState,
    state: companyState,
    setState: setCompanyState,
    selectedProviderId: selectedCompanyId,
    setSelectedProviderId: setSelectedCompanyId
  } = useServiceProviderForm<CompanyServiceProvider>();

  const {
    onChange: onChangeBranchState,
    state: branchState,
    setState: setBranchState,
    selectedProviderId: selectedBranchId,
    setSelectedProviderId: setSelectedBranchId
  } = useServiceProviderForm<BranchServiceProvider>();

  const {
    onChange: onChangeRepState,
    state: repState,
    setState: setRepState,
    selectedProviderId: selectedRepId,
    setSelectedProviderId: setSelectedRepId
  } = useServiceProviderForm<RepresentativeServiceProvider>();

  useEffect(() => {
    if (isShow) {
      setSelectedConfiguration(data.domainConfigurationId);
      setSelectedEntities(data?.dealEntities?.[dealId]?.entityIds || []);
      setSelectedCompanyId(data.companyId);
      setSelectedBranchId(data.branchId);
      setSelectedRepId(data.repId);
    }
  }, [isShow]);

  const handleProviderFormValidation = (isValid: boolean) => {
    setIsProviderFormValid(isValid);
  };

  const handleOnSubmit = () => {
    if (
      !selectedConfiguration ||
      !selectedEntities.length ||
      !selectedDeal ||
      !companyState?.id ||
      !branchState?.id ||
      !repState.id
    ) {
      return;
    }
    updateHandler({
      dealId: selectedDeal.id,
      entityIds: selectedEntities,
      entityType: selectedConfiguration.entityType,
      company: getCompanyTemplate(companyState),
      branch: getBranchTemplate(branchState),
      rep: getRepTemplate(repState)
    }).then((r) => {
      if (r.status === StatusCode.OK) {
        onClose();
      }
    });
  };

  return (
    <ModalContainer
      maxWidth={1110}
      isShow={isShow}
      onCloseModal={onClose}
      header={"Edit a Service Provider"}
      body={
        <WizardContainer
          height={500}
          steps={[
            {
              label: "Providers",
              isValid: isProviderFormValid,
              content: (
                <ProvidersFormStepContent
                  isReadOnly={true}
                  companyForm={{
                    state: companyState,
                    onChangeState: onChangeCompanyState,
                    setState: (selected) => {
                      setCompanyState(selected);
                    },
                    selectedProviderId: selectedCompanyId,
                    setSelectedProviderId: (id) => {
                      setSelectedCompanyId(id);
                    }
                  }}
                  branchForm={{
                    state: branchState,
                    onChangeState: onChangeBranchState,
                    setState: (selected) => {
                      setBranchState(selected);
                    },
                    selectedProviderId: selectedBranchId,
                    setSelectedProviderId: (id) => {
                      setSelectedBranchId(id);
                    }
                  }}
                  repForm={{
                    state: repState,
                    onChangeState: onChangeRepState,
                    setState: (selected) => {
                      setRepState(selected);
                    },
                    selectedProviderId: selectedRepId,
                    setSelectedProviderId: (id) => {
                      setSelectedRepId(id);
                    }
                  }}
                  onFormValidation={handleProviderFormValidation}
                />
              )
            },
            {
              label: "Entities",
              isValid: !!selectedEntities.length,
              content: (
                <EntitiesStepContent
                  currentValue={selectedEntities}
                  onChange={setSelectedEntities}
                />
              )
            }
          ]}
          onCancel={onClose}
          onSubmit={handleOnSubmit}
          isLoading={upsertResponse.isLoading}
        />
      }
    />
  );
};
