import { PartyGroup } from "@elphi/types";
import {
  EntityId,
  EntityState,
  PayloadAction,
  createSlice
} from "@reduxjs/toolkit";
import { difference, union } from "lodash";
import sliceBuilder from "../builders/slice.builder";
import { partyGroupAdapter } from "./partyGroup.adapter";
import { partyGroupApi } from "./partyGroup.service";

export type PartyGroupSliceState = EntityState<PartyGroup> & {
  selectedIds: EntityId[];
  excludedIds: EntityId[];
  selectedSearchResultId?: EntityId;
  isAllChecked: boolean;
  totalPartyGroups: number;
};

const initialState: PartyGroupSliceState = {
  ids: [],
  entities: {},
  selectedIds: [],
  excludedIds: [],
  selectedSearchResultId: undefined,
  isAllChecked: false,
  totalPartyGroups: 0
};

export const partyGroupSlice = createSlice({
  name: "partyGroup",
  initialState: partyGroupAdapter.getInitialState(initialState),
  reducers: {
    updateMany: partyGroupAdapter.updateMany,
    upsertMany: (state, action: { payload: PartyGroup[] }) => {
      const upsertIds = action.payload.map((x) => x.id);
      const diff = difference(upsertIds, state.ids);
      partyGroupAdapter.upsertMany(state, action.payload);
      state.totalPartyGroups += diff.length;
      if (state.isAllChecked) {
        state.selectedIds.push(...diff);
      }
    },
    removeMany: (state, action: { payload: EntityId[] }) => {
      const ids = action.payload;
      partyGroupAdapter.removeMany(state, ids);
      state.totalPartyGroups -= ids.length;

      if (state.isAllChecked) {
        state.selectedIds = state.selectedIds.filter((id) => !ids.includes(id));
      }
    },
    setTotalCount: (state, action: PayloadAction<{ count: number }>) => {
      state.totalPartyGroups = action.payload.count;
    },
    selectId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedIds.push(action.payload.id);
      state.excludedIds = state.excludedIds.filter(
        (id) => id !== action.payload.id
      );
    },
    unSelectId: (state, action: PayloadAction<{ id: EntityId }>) => {
      const filtered = state.selectedIds.filter(
        (id) => id !== action.payload.id
      );
      if (state.selectedIds.length > filtered.length && state.isAllChecked) {
        state.excludedIds.push(action.payload.id);
      }

      state.selectedIds = filtered;
    },
    selectAllIds: (state) => {
      state.selectedIds = state.ids;
      state.excludedIds = [];
      state.isAllChecked = true;
    },
    unSelectAll: (state) => {
      state.selectedIds = [];
      state.excludedIds = [];
      state.isAllChecked = false;
    },
    setPartyGroupId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedSearchResultId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(partyGroupApi)(builder);
    builder.addMatcher(
      partyGroupApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.results.ids);
        state.entities = { ...state.entities, ...payload.results.entities };
      }
    );
    builder.addMatcher(
      partyGroupApi.endpoints.getBatch.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.batch.ids);
        state.entities = { ...state.entities, ...payload.batch.entities };
      }
    );
    builder.addMatcher(
      partyGroupApi.endpoints.paginate.matchFulfilled,
      (state, { payload }) => {
        if (state.isAllChecked) {
          state.selectedIds.push(...payload.ids);
        }
      }
    );
    builder.addMatcher(
      partyGroupApi.endpoints.getCount.matchFulfilled,
      (state, { payload }) => {
        state.totalPartyGroups = payload.count;
      }
    );
  }
});
