import {
  ConditionNode,
  ElphiEntityType,
  ExpressionRuleNode,
  OperationNode,
  RuleNodeType,
  ValueNodeType
} from "@elphi/types";
import { v4 as uuid } from "uuid";
import { ElphiNode } from "../../tree/types/Tree.types";
import { FunctionNodeType } from "../node-form/sections/ruleNodeSections";

export const elphiTreeNodeToRuleObject = (
  node: ElphiNode<ConditionNode | OperationNode | null>
) => {
  return {
    ...node.data,
    children: node.children.length
      ? node.children.map((c) => elphiTreeNodeToRuleObject(c))
      : undefined
  };
};

export const valueSchemaAdapter = (
  node:
    | Partial<
        ExpressionRuleNode & {
          path?: string | string[];
          field?: { path?: string | string[] };
        }
      >
    | undefined,
  includeEntities: {
    party: boolean;
    deal: boolean;
    property: boolean;
    dealParty: boolean;
    partyRelation: boolean;
    dealProperty: boolean;
    asset: boolean;
    statement: boolean;
    creditScore: boolean;
    task: boolean;
  }
) => {
  if (node?.type === RuleNodeType.Value) {
    node.valueType = node.valueType || ValueNodeType.String;
    node.value =
      !!node?.value && Array.isArray(node.value)
        ? node.value.join(",")
        : node.value;
  } else if (node?.type === RuleNodeType.Field) {
    const entityKey = getKeyFromEntityType(node?.entityType ?? "");
    if (entityKey) {
      includeEntities[entityKey] = true;
    }
    if (node.path) {
      if (typeof node.path === "string") {
        node.path = node.path?.split(".");
      }
    }
  } else if (node?.type === RuleNodeType.Function) {
    if (node.functionType === "dateDelta") {
      if (node.field?.entityType) {
        const entityKey = getKeyFromEntityType(node?.field?.entityType ?? "");
        if (entityKey) {
          includeEntities[entityKey] = true;
        }
      }
      if (typeof node.field?.path === "string") {
        node.field.path = node.field?.path?.split(".");
      }
    }
  }
};
export const ruleSchemaAdapter = (
  node: ConditionNode | OperationNode | undefined,
  includeEntities: {
    party: boolean;
    deal: boolean;
    property: boolean;
    dealParty: boolean;
    partyRelation: boolean;
    dealProperty: boolean;
    asset: boolean;
    statement: boolean;
    creditScore: boolean;
    task: boolean;
  }
) => {
  if (node?.type === RuleNodeType.Condition) {
    node.children?.forEach((c) => {
      ruleSchemaAdapter(c, includeEntities);
    });
  } else if (node?.type === RuleNodeType.Operation) {
    valueSchemaAdapter(node.left, includeEntities);
    valueSchemaAdapter(node.right, includeEntities);
  }
};

export const ruleSchema = (
  node: OperationNode | ConditionNode
): {
  rule: OperationNode | ConditionNode;
  includeEntities: {
    party: boolean;
    deal: boolean;
    property: boolean;
    dealParty: boolean;
    partyRelation: boolean;
    dealProperty: boolean;
    asset: boolean;
    statement: boolean;
    creditScore: boolean;
    task: boolean;
    serviceProvider: boolean;
  };
} => {
  const includeEntities = {
    party: false,
    property: false,
    deal: false,
    dealParty: false,
    partyRelation: false,
    dealProperty: false,
    asset: false,
    statement: false,
    creditScore: false,
    task: false,
    serviceProvider: false
  };
  ruleSchemaAdapter(node, includeEntities);
  return {
    rule: node,
    includeEntities
  };
};

export const expressionNodeToTreeNodeData = (
  node:
    | ExpressionRuleNode
    | Partial<
        Omit<ExpressionRuleNode, "path" | "field" | "functionType"> & {
          path?: string | string[];
          field?: { path?: string | string[] };
          functionType: FunctionNodeType;
        }
      >
    | undefined
) => {
  if (node?.type === RuleNodeType.Field) {
    if (node.path) {
      if (typeof node.path === "object") {
        node.path = node.path?.join(".");
      }
    }
  } else if (node?.type === RuleNodeType.Function) {
    if (node.functionType === "dateDelta") {
      if (typeof node.field?.path === "object") {
        node.field.path = node.field?.path?.join(".");
      }
    }
  }
};
export const createTreeNodesFromRule = (
  node: OperationNode | ConditionNode
): ElphiNode<OperationNode | ConditionNode | undefined> => {
  const id = uuid();
  if (node.type === RuleNodeType.Operation) {
    expressionNodeToTreeNodeData(node.left);
    expressionNodeToTreeNodeData(node.right);
  }
  const currentNode: ElphiNode<OperationNode | ConditionNode | undefined> = {
    data: node,
    children:
      node.type === RuleNodeType.Condition && node.children
        ? node.children?.map((c) => createTreeNodesFromRule(c))
        : [],
    id: id,
    nodeKey: id,
    nodeActions: []
  };
  return currentNode;
};

export const getKeyFromEntityType = (v: string) => {
  if (v === ElphiEntityType.party) {
    return "party";
  } else if (v === ElphiEntityType.property) {
    return "property";
  } else if (v === ElphiEntityType.deal) {
    return "deal";
  } else if (v === ElphiEntityType.dealParty) {
    return "dealParty";
  } else if (v === ElphiEntityType.partyRelation) {
    return "partyRelation";
  } else if (v === ElphiEntityType.dealProperty) {
    return "dealProperty";
  } else if (v === ElphiEntityType.asset) {
    return "asset";
  } else if (v === ElphiEntityType.statement) {
    return "statement";
  } else if (v === ElphiEntityType.creditScore) {
    return "creditScore";
  } else if (v === ElphiEntityType.task) {
    return "task";
  }
  return "";
};
