import { ElphiDate, elphiDate } from "@elphi/utils";

const MAX_DAYS = 42;

export type CalendarDayData = {
  currentMonth: boolean;
  date: Date;
  isToday?: boolean;
  showDayName?: boolean;
  monthName?: string;
};

export interface CalendarHooks {
  generateDates: (
    month?: number,
    year?: number,
    startWeekInMonday?: boolean
  ) => CalendarDayData[];
}

export const useCalendarHooks = (): CalendarHooks => {
  const getFirstDayOfMonth = (year: number, month: number) =>
    elphiDate().year(year).month(month).startOf("month");

  const getLastDayOfMonth = (year: number, month: number) =>
    elphiDate().year(year).month(month).endOf("month");

  const getPrefixDates = (
    firstDayOfMonth: ElphiDate,
    startDayIndex: number,
    startWeekInMonday: boolean
  ) => {
    const arrayOfDates: CalendarDayData[] = [];

    for (let index = 0; index < startDayIndex; index++) {
      arrayOfDates.push({
        currentMonth: false,
        date: firstDayOfMonth.day(startWeekInMonday ? index + 1 : index),
        showDayName: true
        /*monthName: firstDayOfMonth
        .date(index - 1)
        .toLocaleString("en-US", { month: "long" })*/
      });
    }

    return arrayOfDates;
  };

  const getCurrentDates = (
    firstDayOfMonth: ElphiDate,
    lastDayOfMonth: ElphiDate,
    startDayIndex: number
  ) => {
    const arrayOfDates: CalendarDayData[] = [];

    for (
      let index = firstDayOfMonth.dateIndex();
      index <= lastDayOfMonth.dateIndex();
      index++
    ) {
      arrayOfDates.push({
        currentMonth: true,
        date: firstDayOfMonth.date(index),
        isToday:
          firstDayOfMonth.date(index).toDateString() ===
          elphiDate().toDate().toDateString(),
        showDayName: startDayIndex + index <= 7,
        monthName:
          index === 1
            ? firstDayOfMonth
                .date(index)
                .toLocaleString("en-US", { month: "long" })
            : undefined
      });
    }

    return arrayOfDates;
  };

  const getFutureDates = (lastDayOfMonth: ElphiDate, remaining: number) => {
    const arrayOfDates: CalendarDayData[] = [];

    for (
      let index = lastDayOfMonth.dateIndex() + 1;
      index <= lastDayOfMonth.dateIndex() + remaining;
      index++
    ) {
      arrayOfDates.push({
        currentMonth: false,
        date: lastDayOfMonth.date(index)
        /*monthName: lastDayOfMonth
          .date(index)
          .toLocaleString("en-US", { month: "long" })*/
      });
    }

    return arrayOfDates;
  };

  const getFirstDayOfWeek = (
    firstDayOfMonth: ElphiDate,
    startWeekInMonday: boolean
  ) => {
    const startDayIndex = firstDayOfMonth.dayIndex();
    if (startWeekInMonday) {
      return (startDayIndex + 6) % 7;
    }
    return startDayIndex;
  };

  const generateDates = (
    month = elphiDate().monthIndex(),
    year = elphiDate().yearIndex(),
    startWeekInMonday = false // Default starting the week on Sunday
  ) => {
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const lastDayOfMonth = getLastDayOfMonth(year, month);

    const arrayOfDates: CalendarDayData[] = [];

    const startDayIndex = getFirstDayOfWeek(firstDayOfMonth, startWeekInMonday);

    const prefixDates = getPrefixDates(
      firstDayOfMonth,
      startDayIndex,
      startWeekInMonday
    );
    arrayOfDates.push(...prefixDates);

    const currentDates = getCurrentDates(
      firstDayOfMonth,
      lastDayOfMonth,
      startDayIndex
    );
    arrayOfDates.push(...currentDates);

    const remaining = MAX_DAYS - arrayOfDates.length;

    const futureDates = getFutureDates(lastDayOfMonth, remaining);
    arrayOfDates.push(...futureDates);

    return arrayOfDates;
  };

  return {
    generateDates
  };
};
